import React, { useEffect, useState, useContext, useRef } from "react";
import PropTypes from "prop-types";
import Styles from "../../slideThree.module.css";
import API from "../../../../../../../../../Services/API";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { SimpleGrid, Button, Box, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, useDisclosure, FormControl, FormLabel } from "@chakra-ui/react";
import JoditEditor from 'jodit-react';
import LoaderSpinner from "../../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../../../../../Widgets/ErrorWidget/ErrorWidget";


const AddNewRecord = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const editorGoal = useRef(null);
    const editorAchievements = useRef(null);
    const editorNextSteps = useRef(null);
    const [goals, setGoals] = useState(props.goals || '');
    const [achievements, setAchievements] = useState(props.achievements || '');
    const [nextSteps, setNextSteps] = useState(props.nextSteps || '');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)
    const [user, setUser] = useState({})
    
    const handleChange = (selected) => {
        setSelectedOption(selected);
    };
    
    const resetForm = () => {
        setGoals('');
        setAchievements('');
        setNextSteps('');
        setSelectedOption(null);
    };

    useEffect(() => {
        if (isOpen) {
            API.get(`/get-specific-modules?module_id=${actionid}`, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            }).then((response) => {
                setUser(response.data);
            }).catch(error => {
            });
            resetForm()
        }
    }, [isOpen]);

    const saveRecord = () =>{
        setIsLoading(true)
        setMessage(null)
        const body = {
            approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Ready to Submit",
            achievements_to_date: document.getElementById('getAchievements').value,
            next_steps: document.getElementById('getNextSteps').value,
            goals: document.getElementById('getGoals').value,
            visible: true,
            action: props.action.id,
            selectedfy: props.selectedFiscalYearID,
            strategy: selectedOption.value,
            "module_id":actionid,
        };
        API.post(`/progress-next-steps`, body, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            props.getUpdatedRecords()
            setMessage('Created new step successfully')
            onClose()
            setIsLoading(false)
        }).catch(error=>{
            setMessage(
                error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                error.response.data.msg ? error.response.data.msg : 'Facing technical error'
            )
            onClose()
            setIsLoading(false)
        })
    }


    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Button onClick={onOpen} borderColor={'#63b3ed'} color={'#ffffff'}  variant='outline' _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}} margin={'5px'} float={'right'}> Add new </Button> 
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent maxW="800px">
                    <ModalHeader>Add New Record</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight="bold">Select Strategy</FormLabel>
                            <Select
                                options={props.strategiesOptions}
                                value={selectedOption}
                                onChange={handleChange}
                                placeholder="Select a strategy"
                            />
                        </FormControl>
                        <SimpleGrid columns={3} spacing={1}>
                            <FormControl>
                                <FormLabel fontWeight="bold">Goals</FormLabel>
                                <JoditEditor
                                    ref={editorGoal}
                                    value={goals}
                                    //onChange={(newContent) => setGoals(newContent)}
                                    tabIndex={1}
                                    id={'getGoals'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Achievements to Date</FormLabel>
                                <JoditEditor
                                    ref={editorAchievements}
                                    value={achievements}
                                    //onChange={(newContent) => setAchievements(newContent)}
                                    tabIndex={1}
                                    id={'getAchievements'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel fontWeight="bold">Next Steps</FormLabel>
                                <JoditEditor
                                    ref={editorNextSteps}
                                    value={nextSteps}
                                    //onChange={(newContent) => setNextSteps(newContent)}
                                    tabIndex={1}
                                    id={'getNextSteps'}
                                />
                            </FormControl>
                        </SimpleGrid>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button backgroundColor={'transparent'} mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme="blue" onClick={saveRecord}>Save</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default AddNewRecord