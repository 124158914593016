import React, { useState, useMemo, useEffect } from 'react';
import Styles from './metricdata.module.css';
import API from '../../../Services/API';
import { IconButton } from '@chakra-ui/react';
import { CloseIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  FormLabel,
  Tooltip,
  useDisclosure,
  Alert,AlertIcon
} from '@chakra-ui/react';
import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import Select from 'react-select';
import ErrorWidget from '../../../Components/Widgets/ErrorWidget/ErrorWidget';

const RejectComponent = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [selectedOption, setSelectedOption] = useState(null);

  const rejectOptions = useMemo(() => [
    { value: 'Data value is incorrect', label: 'Data value is incorrect' },
    { value: 'Fiscal period is incorrect', label: 'Fiscal period is incorrect' },
    { value: 'Geography Misalignment', label: 'Geography Misalignment' },
    { value: 'Incorrect External Data Setting', label: 'Incorrect External Data Setting' },
    { value: 'Metric Status Incorrect', label: 'Metric Status Incorrect' },
    { value: 'other', label: 'Other' },
  ], []);

  useEffect(() => {
    if (!isOpen) {
      setSelectedOption(null);
      setMessage('');
    }
  }, [isOpen]);

  const handleRejectMetric = (event) => {
    setMessage(null)
    if (!selectedOption) {
      setMessage('Please select a reason for rejection');
      return;
    }


    onClose();
    setIsLoading(true);

    const body = {
      id: parseInt(event.target.id),
      approval_status: 'rejected',
      reject_reason: selectedOption ? selectedOption.value : null,
    };

    API.put('/metric-data', body, {
      headers: {
        'Authorization': 'Bearer ' + token,
      },
    })
    .then((response) => {
      API.get('/my-task', {
        headers: {
          'Authorization': 'Bearer ' + token,
        },
      })
        .then((response) => {
          const sortedMetrics = response.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
          props.getUpdatedPending(response.data);
          setIsLoading(false);
          setMessage('Metric data has been successfully rejected!');
        })
        .catch((error) => {
          setIsLoading(false);
          setMessage('Error fetching updated tasks. Please try again.');
        });
    })
    .catch((error) => {
      setMessage(error.response.data.msg ? error.response.data.msg : 'Failed to reject metric data. Please try again.');
      setIsLoading(false);
      onClose();
      // setMessage('Rejection failed. Please try again.');
    });
  };

  const handleChange = (option) => {
    setSelectedOption(option);
  };

  return (
    <>
      {isLoading && <LoaderSpinner />}
      {message && <ErrorWidget message={message} />}
      
      {/* <Tooltip label="Reject" aria-label="Reject Metric">
        <IconButton
          aria-label="Reject Metric"
          variant="outline"
          colorScheme="teal"
          fontSize="14px"
          icon={<CloseIcon />}
          className={`${Styles.reject} ${Styles.hoverEffectReject}`}
          id={props.id}
          onClick={onOpen}
        />
      </Tooltip> */}
      <Tooltip id={props.id} label="Reject metric data" aria-label="Reject metric data">
        <IconButton
          variant="solid"
          aria-label="Reject"
          fontSize="15px"
          icon={<CloseIcon id={props.id} />}
          className={Styles.edit}
          id={props.id}
          onClick={onOpen}
          ml={'5px'}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
        <ModalOverlay className={Styles.overLay} />
        <ModalContent className={Styles.contentZindex}>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel mt={'10px'}>Please select the reason to reject</FormLabel>
            <Select
              options={rejectOptions}
              onChange={handleChange}
              placeholder="Select an option"
              value={selectedOption}
              isSearchable={false} // Disable search if you don't want it
            />
          </ModalBody>
          <ModalFooter className={Styles.modelFooter}>
            <Button mr={3} size="sm" onClick={onClose}>
              Cancel
            </Button>
            <Button
              className={Styles.confirmButton}
              id={props.id}
              size="sm"
              onClick={handleRejectMetric}
              disabled={!selectedOption}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default RejectComponent;
