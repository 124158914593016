import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import API from "../../../../../../../Services/API";
import { Text, Spinner, Box } from "@chakra-ui/react";

const Title = () => {
  const token = window.localStorage.getItem("accessToken");
  const { actionid } = useParams();
  const [action, setAction] = useState(null);
  const [loading, setLoading] = useState(true);
  
  // const beforeText = action?.name?.split(/[-–]/)[0]?.trim() || "";
  // const afterText = action?.name?.split(/[-–]/)[1]?.trim() || "";

  const beforeText = action?.name?.split(/[-–]/)?.[0]?.trim() || "";
  const afterText = action?.name?.split(/[-–]/)?.slice(1).join('-')?.trim() || "";
  
  useEffect(() => {
    setLoading(true);
    API.get(`/pillar?module_id=${actionid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => {
        setAction(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching action:", error);
        setLoading(false);
      });
  }, [actionid, token]);

  return (
    <Box>
      {loading ? (
        <Spinner size="xl" color="blue.300" />
      ) : (
        <>
          {beforeText && (
            <Text fontSize="4xl" fontWeight="bold" mb={4} ml="20px" color="blue.300">
              {beforeText}
            </Text>
          )}
          {afterText && (
            <Text fontSize="xl" mb={4} ml="20px" color="blue.300">
              {afterText}
            </Text>
          )}
        </>
      )}
    </Box>
  );
};

export default Title;
