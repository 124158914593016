import React, { useState, useEffect } from 'react';
import { Box, Text, VStack, List, ListItem, Divider, Spinner } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import API from '../../Services/API';

const SearchResults = () => {
    const token = window.localStorage.getItem('accessToken');
    const location = useLocation();
    const [query, setQuery] = useState(''); // Store the search query
    const [data, setData] = useState({
        metrics: [],
        pillars: [],
        pillarActions: [],
        blogList: [],
        articles: [],
        tags: [],
    }); // Consolidated state for data
    const [results, setResults] = useState({}); // Grouped filtered results
    const [isLoading, setIsLoading] = useState(true); // Loading state
    const [error, setError] = useState(null); // Error state

    // Extract the search query from the URL
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const searchQuery = params.get('q') || '';
        setQuery(searchQuery);
    }, [location]);

    // Fetch data on component mount
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setError(null); // Reset error state
            try {
                const [metricsRes, pillarsRes, actionsRes, blogsRes, articlesRes, tagsRes] = await Promise.all([
                    API.get('/all-metric-dashboard', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/sio-pillar', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/get-pillar-action', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/blog', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/about-article', { headers: { Authorization: `Bearer ${token}` } }),
                    API.get('/tag-detail', { headers: { Authorization: `Bearer ${token}` } }),
                ]);
                const SocialJusticiMetric= metricsRes?.data.filter((item) => item?.action_id__name?.includes("Community Enablement"));
                const SocialJusticiPillar= pillarsRes.data.filter((item) => item?.name?.includes("Community Enablement"));
                const SocialJusticiaction= actionsRes.data.filter((item) => item?.name?.includes("Community Enablement"));
                
                setData({
                    metrics: SocialJusticiMetric || [],
                    pillars: SocialJusticiPillar || [],
                    pillarActions: SocialJusticiaction || [],
                    blogList: blogsRes.data || [],
                    articles: articlesRes.data?.results || [],
                    tags: tagsRes.data?.results || [],
                    
                });
            } catch (error) {
                console.error('Error fetching data:', error);
                setError('Failed to load data. Please try again.');
            } finally {
                setIsLoading(false);
            }
        };
        fetchData();
    }, [token]);

    // Filter results based on the query
    useEffect(() => {
        const groupedResults = {
            Metrics: 
            (data.metrics || []).filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
            ),
            'SIIO Organization': (data.pillars || []).filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
            ),
            'Actions': (data.pillarActions || []).filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
            ),
            'Impact Gallery': (data.blogList || []).filter((item) =>
                item.title.toLowerCase().includes(query.toLowerCase())
            ),
            'Articles': (data.articles || []).filter((item) =>
                item.article_name.toLowerCase().includes(query.toLowerCase())
            ),
            'Tags': (data.tags || []).filter((item) =>
                item.name.toLowerCase().includes(query.toLowerCase())
            ),
        };
        setResults(groupedResults);
    }, [query, data]);

    return (
        <Box p={4}>
            <Text fontSize="2xl" fontWeight="bold" mb={4}>
                Search Results
            </Text>
            <Text fontSize="md" mb={4}>
                Showing results for: <strong>{query}</strong>
            </Text>
            {error && <Text color="red.500">{error}</Text>}
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
                    <Spinner size="xl" color="teal.500" />
                </Box>
            ) : (
                <VStack align="start" spacing={4}>
                    {Object.entries(results).map(([group, items], index) => (
                        <Box key={index} w="100%">
                            <Text fontSize="lg" fontWeight="bold" color="teal.600">
                                {group}
                            </Text>
                            {items.length > 0 ? (
                                <List spacing={2}>
                                    {items.map((item, itemIndex) => (
                                        <ListItem
                                            key={itemIndex}
                                            p={2}
                                            borderRadius="md"
                                            _hover={{ bg: 'teal.50', cursor: 'pointer' }}
                                        >
                                            <a
                                                href={
                                                    group === 'Actions'
                                                        ? `/action/${item.module}/${encodeURIComponent(item.name)}`
                                                        : group === 'Impact Gallery'
                                                        ? `/viewpost/${item.id}/${encodeURIComponent(item.title)}`
                                                        : group === 'SIIO Organization'
                                                        ? `/pillar/${item.module}/${encodeURIComponent(item.name)}`
                                                        : group === 'Articles'
                                                        ? `/article/${item.id}/${encodeURIComponent(item.article_name)}`
                                                        : group === 'Tags'
                                                        ? `portfolio/tg/251/Tags/${item.id}/${encodeURIComponent(item.name)}`
                                                        : item.metrictype === 'impact'
                                                        ? `/dashboard/impactmetrics/${item.id}/${encodeURIComponent(item.name)}`
                                                        : `/metric/${item.id}/${encodeURIComponent(item.name)}`
                                                }
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                rel="noopener noreferrer"
                                                aria-label={`Go to ${group} item ${item.name || item.title}`}
                                            >
                                                {group === 'Impact Gallery'
                                                    ? item.title
                                                    : group === 'Articles'
                                                    ? item.article_name
                                                    : item.name}
                                            </a>
                                        </ListItem>
                                    ))}
                                </List>
                            ) : (
                                <Text fontSize="sm" color="gray.500" mt={2}>
                                    This search query has yielded no results within the system.
                                </Text>
                            )}
                            {index < Object.entries(results).length - 1 && <Divider my={4} />}
                        </Box>
                    ))}
                </VStack>
            )}
        </Box>
    );
};

export default SearchResults;
