import React, { useEffect, useState } from 'react';
import Styles from './viewpdf.module.css'
import SlidingPane from "react-sliding-pane";
import jsPDF from 'jspdf';
import pptxgen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Button, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure } from '@chakra-ui/react';
import PDFContent from './PDFContent/PDFContent';

const ViewPdf = (props) => {
    const [isPaneOpen, setIsPaneOpen] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const btnRef = React.useRef();

    const printPDF = async () => {
        try {
            const customPageWidth = 297; // A4 landscape width in mm
            const customPageHeight = 210; // A4 landscape height in mm
    
            const pdf = new jsPDF('l', 'mm', [customPageWidth, customPageHeight]);
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
    
            const headings = [
                '', // No heading for the first page
                'Executive Leader Reporting',
                'Impact Stories',
                'Executive Level Review',
            ];
    
            const footerText = 'Page';
            const sectionIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2', 'pdf-content-3'];
    
            const headerHeight = 15; // Header height in mm
            const footerHeight = 10; // Footer height in mm
            const headingBgColor = '#062341'; // Background color for the heading
    
            const scaleToFit = (imgWidth, imgHeight, maxWidth, maxHeight) => {
                const widthScaleFactor = maxWidth / imgWidth;
                let finalWidth = imgWidth * widthScaleFactor;
                let finalHeight = imgHeight * widthScaleFactor;
    
                if (finalHeight > maxHeight) {
                    const heightScaleFactor = maxHeight / imgHeight;
                    finalWidth = imgWidth * heightScaleFactor;
                    finalHeight = imgHeight * heightScaleFactor;
                }
                return { finalWidth, finalHeight };
            };
    
            for (let i = 0; i < sectionIds.length; i++) {
                const element = document.getElementById(sectionIds[i]);
                if (element) {
                    // Handle sectionNew within pdf-content-2
                    if (sectionIds[i] === 'pdf-content-2') {
                        const sections = element.querySelectorAll('.sectionNew');
                        for (let j = 0; j < sections.length; j++) {
                            const section = sections[j];
    
                            // Wait for all images within the section to be loaded
                            const images = section.querySelectorAll('img');
                            const loadPromises = Array.from(images).map((img) => new Promise((resolve) => {
                                if (img.complete) {
                                    resolve();
                                } else {
                                    img.onload = resolve;
                                    img.onerror = resolve; // In case of image load failure
                                }
                            }));
    
                            await Promise.all(loadPromises);
    
                            const canvas = await html2canvas(section, { scale: 2, useCORS: true });
                            const imgData = canvas.toDataURL('image/png');
    
                            const { finalWidth, finalHeight } = scaleToFit(
                                canvas.width,
                                canvas.height,
                                pageWidth,
                                pageHeight - headerHeight - footerHeight
                            );
    
                            const xOffset = (pageWidth - finalWidth) / 2;
                            const yOffset = headerHeight + (pageHeight - headerHeight - footerHeight - finalHeight) / 2;
    
                            if (i > 0 || j > 0) {
                                pdf.addPage();
                            }
    
                            // Add section header for pdf-content-2
                            const headerText = headings[i];
                            pdf.setFillColor(headingBgColor);
                            pdf.rect(0, 0, pageWidth, headerHeight, 'F');
    
                            pdf.setFont('Helvetica', 'bold');
                            pdf.setFontSize(14);
                            pdf.setTextColor('#FFFFFF');
    
                            const textHeight = pdf.getTextDimensions(headerText).h;
                            const textY = headerHeight / 2 + textHeight / 2;
    
                            pdf.text(headerText, pageWidth / 2, textY, { align: 'center' });
    
                            pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);
                        }
                    } else {
                        // Wait for images to load within the other sections
                        const images = element.querySelectorAll('img');
                        const loadPromises = Array.from(images).map((img) => new Promise((resolve) => {
                            if (img.complete) {
                                resolve();
                            } else {
                                img.onload = resolve;
                                img.onerror = resolve;
                            }
                        }));
    
                        await Promise.all(loadPromises);
    
                        const canvas = await html2canvas(element, { scale: 2, useCORS: true, ignoreElements: (el) => el.id === 'excludeId' });
                        const imgData = canvas.toDataURL('image/png');
    
                        const { finalWidth, finalHeight } = scaleToFit(
                            canvas.width,
                            canvas.height,
                            pageWidth,
                            pageHeight - headerHeight - footerHeight
                        );
    
                        const xOffset = (pageWidth - finalWidth) / 2;
                        const yOffset = headerHeight + (pageHeight - headerHeight - footerHeight - finalHeight) / 2;
    
                        if (i > 0) {
                            pdf.addPage();
                        }
    
                        if (i === 0) {
                            pdf.setFillColor('#062341');
                            pdf.rect(0, 0, pageWidth, pageHeight, 'F');
                        } else {
                            const headerText = headings[i];
                            pdf.setFillColor(headingBgColor);
                            pdf.rect(0, 0, pageWidth, headerHeight, 'F');
    
                            pdf.setFont('Helvetica', 'bold');
                            pdf.setFontSize(13);
                            pdf.setTextColor('#FFFFFF');
    
                            const textHeight = pdf.getTextDimensions(headerText).h;
                            const textY = headerHeight / 2 + textHeight / 2;
    
                            pdf.text(headerText, pageWidth / 2, textY, { align: 'center' });
                        }
    
                        pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);
                    }
                } else {
                    console.error(`Element with ID ${sectionIds[i]} not found.`);
                }
            }
    
            // Add the footer element separately
            const footerElement = document.getElementById('footerPdf');
            if (footerElement) {
                try {
                    const footerCanvas = await html2canvas(footerElement, {
                        scale: 2,
                        useCORS: true,
                        backgroundColor: null // Makes the background transparent
                    });
                    const footerImgData = footerCanvas.toDataURL('image/png');
    
                    // Set width for the footer image (adjust as necessary)
                    const footerWidth = pageWidth * 0.5; // 50% of page width
                    const footerHeight = (footerCanvas.height / footerCanvas.width) * footerWidth;
    
                    // Position the footer on the left side at the bottom with a small margin
                    const xOffset = 10; // Left margin (10 mm from the left)
                    const footerYOffset = pageHeight - footerHeight - 10; // Bottom Y-offset with 10 mm margin
    
                    // Add footer to the bottom left of the first page only
                    pdf.setPage(1); // Ensure footer is added to the first page
                    pdf.addImage(
                        footerImgData,
                        'PNG',
                        xOffset,          // Left alignment
                        footerYOffset,    // Bottom Y-offset
                        footerWidth,
                        footerHeight
                    );
                } catch (error) {
                    console.error("Error capturing or adding footer:", error);
                }
            } else {
                console.error("Footer element with ID 'footerPdf' not found.");
            }
            pdf.save('document.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    
    const downloadPPT = async () => {
        try {
            const pptx = new pptxgen();
            const elementIds = ['pdf-content-0', 'pdf-content-1', 'pdf-content-2', 'pdf-content-3'];
            const excludeId = 'excludeId'; // Define the ID you want to exclude
    
            // Define slide dimensions (width and height in inches)
            const slideWidthInches = 10;  // Slide width in inches
            const slideHeightInches = 5.63; // Slide height in inches
            const fullSlideHeightInches = '100%';
    
            let isFirstSlide = true; // Flag to identify the first slide
    
            const footerElement = document.getElementById('footerPdf');
            let footerText = '';
            let footerImageData = null;
    
            if (footerElement) {
                // Get footer text
                footerText = footerElement.innerText || '';
                // Check for an image inside footerPdf
                const footerImage = footerElement.querySelector('img');
                if (footerImage) {
                    const response = await fetch(footerImage.src);
                    const blob = await response.blob();
                    footerImageData = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.readAsDataURL(blob);
                    });
                }
            }
    
            // Function to add footer to a slide
            const addFooterToSlide = (slide) => {
                if (footerImageData) {
                    const footerImageX = 0.5; // Adjust as needed for your layout
                    const footerImageY = isFirstSlide ? slideHeightInches - 0.8 : fullSlideHeightInches;

                    // Add the image
                    slide.addImage({
                        data: footerImageData,
                        x: footerImageX,
                        y: footerImageY,
                        w: 0.625,
                        h: 0.329
                    });
    
                    // Calculate the position for the footer text
                    const footerTextX = footerImageX + 0.7; // Position to the right of the image
                    const footerTextY = footerImageY + 0.05; // Align with the image vertically
    
                    // Add footer text next to the image
                    slide.addText(footerText, {
                        x: footerTextX,
                        y: footerTextY,
                        w: slideWidthInches - footerTextX - 0.5, // Width of the text box
                        h: 0.3, // Height of the text box
                        fontSize: 9,
                        color: "FFFFFF", // Text color
                        align: pptx.AlignH.left // Left-align the footer text
                    });
                }
            };
    
            for (const id of elementIds) {
                const element = document.getElementById(id);
    
                if (element) {
                    try {
                        // Capture the element as a canvas with ignoreElements option
                        const canvas = await html2canvas(element, {
                            scale: 2, // Increase resolution of the canvas
                            useCORS: true,
                            ignoreElements: (el) => el.id === excludeId // Exclude element by ID
                        });
    
                        const imgData = canvas.toDataURL('image/png');
                        const slide = pptx.addSlide();
    
                        // Add background color to the first slide
                        if (isFirstSlide) {
                            slide.addShape(pptx.ShapeType.rect, {
                                x: 0,
                                y: 0,
                                w: slideWidthInches,
                                h: slideHeightInches,
                                fill: { color: '#062341' } // Background color
                            });
    
                            // Add footer elements for the first slide
                            addFooterToSlide(slide);
                            isFirstSlide = false; // After the first slide, set flag to false
                        }
    
                        // Get the canvas aspect ratio
                        const canvasWidth = canvas.width;
                        const canvasHeight = canvas.height;
                        const aspectRatio = canvasWidth / canvasHeight;
    
                        // Set image dimensions while maintaining aspect ratio
                        let imgWidth = slideWidthInches;
                        let imgHeight = imgWidth / aspectRatio;
    
                        // Adjust dimensions if image height exceeds slide height
                        if (imgHeight > slideHeightInches) {
                            imgHeight = slideHeightInches;
                            imgWidth = imgHeight * aspectRatio;
                        }
    
                        // Calculate positions to center the image
                        const xPos = (slideWidthInches - imgWidth) / 2;
                        const yPos = (slideHeightInches - imgHeight) / 2;
    
                        // Add image to slide
                        slide.addImage({
                            data: imgData,
                            x: xPos,
                            y: yPos,
                            w: imgWidth,
                            h: imgHeight
                        });
    
                    } catch (captureError) {
                        console.error(`Error capturing element with id "${id}":`, captureError);
                    }
    
                } else {
                    console.warn(`Element with id "${id}" not found.`);
                }
            }
    
            // Save the presentation file
            await pptx.writeFile('presentation.pptx');
    
        } catch (error) {
            console.error('Error creating presentation:', error);
        }
    };
    
    
    

    return (
        <>
            <Button className='buttonPrimary' position={'absolute'} right={'30px'} ref={btnRef} colorScheme="teal" onClick={onOpen}>
                View / Download
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                finalFocusRef={btnRef}
                closeOnOverlayClick={false}
            >
                <DrawerOverlay />
                <DrawerContent maxWidth="70%">
                    <DrawerCloseButton />
                    <DrawerHeader>View / Download</DrawerHeader>
                    <DrawerBody>
                        <PDFContent 
                            pillarId={props.pillarId} 
                            title={props.title}
                            firstFiscalYear={props.firstFiscalYear} 
                            firstFiscalQtr={props.firstFiscalQtr}
                        />
                    </DrawerBody>
                    <DrawerFooter className={Styles.footer}>
                        <Button mt="0"  mr={3} colorScheme="blue" onClick={printPDF}>
                            Download as PDF
                        </Button>
                        <Button mt="0"  mr={3} colorScheme="blue" onClick={downloadPPT}>
                            Download as PPT
                        </Button>
                        <Button variant="outline" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </DrawerFooter>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default ViewPdf;