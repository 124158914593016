import React, { useEffect, useState, useContext } from 'react'
import Styles from '../../../../../../Components/ProjectModule/Actions/ViewAction/ActionTabs/Qbr/SlideTwo/slideTwo.module.css';
import { Link, useParams } from 'react-router-dom';
import { Box, Heading, Img, Text } from '@chakra-ui/react';
import {Button, Table,Thead,Tbody,Tr,Th,Td,TableContainer,} from "@chakra-ui/react";
import API from '../../../../../../Services/API';

const Indicators = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [indicators, setIndicators] = useState([])
    const [action, setAction] = useState(null);
    const [qbrData, setQbrData] = useState([]);
    const [indicatorsLength, setIndicatorsLength] = useState(1);
    const [selectedFiscalYearID, setSelectedFiscalYearID] = useState(null);

    useEffect(() => {
        setIndicators([])
        setIndicatorsLength(1)
        getUpdatedData()
    }, [actionid]);

    const groupedIndicators = indicators.reduce((acc, indicator) => {
        if (!acc[indicator.strategy_name]) {
          acc[indicator.strategy_name] = [];
        }
        acc[indicator.strategy_name].push(indicator);
        return acc;
    }, {});

    const getUniquePeriods = (data) => {
      const periods = new Set();
      Object.values(data).forEach((strategyGroup) => {
        strategyGroup.forEach((indicator) => {
          indicator.value.forEach((entry) => {
            if (entry.period) {
              periods.add(entry.period);
            }
          });
        });
      });
      return Array.from(periods).filter((period) => !period.includes("Forecast") || period.match(/Q[1-4]/)).sort();
    };
    const dynamicPeriods = getUniquePeriods(groupedIndicators);
    const filteredPeriods = dynamicPeriods.filter(period => /Q\d/.test(period));
    const previousFiscalYear = indicators.length > 0 ? indicators[0].previous_fiscal_year_name : ' ';

    const getUpdatedData = () =>{
        API.get(`/qbr-key-performance?action_id=${props.actionId}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            if (response.data.length > 0) {
              let status = null;
              const isReadyToSubmit = response.data.some(
                (record) => record.approval_status === "Ready to Submit"
              );
            
              if (isReadyToSubmit) {
                status = "Ready to Submit";
              } else {
                const isApproved = response.data.every(
                  (record) => record.approval_status === "Approved"
                );
            
                if (isApproved) {
                  status = "Approved";
                }
              }
              props.getUpdate(status);
            }
            
        }).catch(error => {
            console.error("Error fetching data:", error);
        });

        API.get(`/qbr-selected-fy?action_id=${props.actionId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then((response) => {
            if(response?.data.length > 0){
                setSelectedFiscalYearID(response?.data[0]?.id)
                API.get(`/get-qbr?fiscal_year=${response?.data[0]?.fiscal_year}&action_id=${response?.data[0]?.action}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((response) => {
                    setIndicators(response.data || []);
                    setIndicatorsLength(response.data.length)
                })
                .catch((error) => {
                    console.error(error);
                });
            }
        }).catch(error=>{

        })
    }

    return (
        <>
            {
                indicatorsLength < 1 ? 
                    <Box fontSize={'12px'} m={'20px 0'} fontStyle={'italic'} border={'1px solid #3ba3ed'} p={'10px 10px'} color={'#3ba3ed'} > Key Performance Indicators (KPIs) are currently unavailable. </Box>
                :
                        <TableContainer  border="1px" mt={'20px'} mb={'20px'} borderColor={'rgb(0 160 218)'}>
                            <Table variant="simple" size="md" border="1px" borderColor="gray.200">
                                <Thead>
                                    <Tr>
                                        <Th className={Styles.th}>
                                            <Box maxW={'200px'} whiteSpace="pre-wrap">
                                                Core Action Strategy
                                            </Box>
                                        </Th>
                                        <Th className={Styles.th}>
                                            <Box maxW={'200px'} whiteSpace="pre-wrap">
                                                Key Performance Indicator
                                            </Box>
                                        </Th>
                                        <Th className={Styles.th}>
                                            <Box maxW={'200px'} whiteSpace="pre-wrap">
                                                5-Year Goal
                                            </Box>
                                        </Th>
                                        <Th className={Styles.th}>
                                            <Box maxW={'200px'} whiteSpace="pre-wrap">
                                                {previousFiscalYear} Realized
                                            </Box>
                                        </Th>
                                        <Th className={Styles.th}>
                                            <Box maxW={'200px'} whiteSpace="pre-wrap">
                                                {dynamicPeriods.length > 0 ? `${dynamicPeriods[0]} Aspirations` : 'Aspirations'}
                                                {/* FY25 Aspirations */}
                                            </Box>
                                        </Th>
                                        {filteredPeriods.map((period) => (
                                            <Th key={period} className={Styles.th}>
                                                <Box maxW="200px" whiteSpace="pre-wrap">
                                                {period} Actuals
                                                </Box>
                                            </Th>
                                        ))}
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        Object.keys(groupedIndicators).map((strategyName, index) => {
                                            const strategyGroup = groupedIndicators[strategyName];
                                            return (
                                                <React.Fragment key={index}>
                                                    {strategyGroup.map((indicator, idx) => (
                                                        <Tr key={idx}>
                                                            {idx === 0 && (
                                                                <Td rowSpan={strategyGroup.length} className={Styles.td}>
                                                                    <Box maxW="200px" whiteSpace="pre-wrap">
                                                                        {strategyName}
                                                                    </Box>
                                                                </Td>
                                                            )}
                                                            <Td className={Styles.td}>
                                                                <Box maxW="200px" whiteSpace="pre-wrap">
                                                                    {indicator.metric_name || "N/A"}
                                                                </Box>
                                                            </Td>
                                                            <Td className={Styles.td}>
                                                                <Box maxW="200px" whiteSpace="pre-wrap">
                                                                    {indicator.value.find((v) => v.type === "5 yr goal")?.value?.[0]?.value ||"N/A"}
                                                                </Box>
                                                            </Td>
                                                            <Td className={Styles.td}>
                                                                <Box maxW="200px" whiteSpace="pre-wrap">
                                                                    {indicator.realize_value || "N/A"}
                                                                </Box>
                                                                {/* {
                                                                    edit ? 
                                                                        <AddIndicators
                                                                            id={action && action.id}
                                                                            fiscalYear = {indicator.previous_fiscal_year}
                                                                            selectedFy={selectedFiscalYearID}
                                                                            metric={indicator.metric}
                                                                            indicators={indicators}
                                                                            value={indicator.realize_value}
                                                                            getUpdatedData={getUpdatedData}
                                                                        /> 
                                                                    : <Box maxW="200px" whiteSpace="pre-wrap">
                                                                        {indicator.realize_value || "N/A"}
                                                                    </Box>
                                                                } */}
                                                            </Td>
                                                            <Td className={Styles.td}>
                                                                <Box maxW="200px" whiteSpace="pre-wrap">
                                                                    {
                                                                    indicator.value.find((v) => v.type === "aspirations")?.value?.[0]?.value ||
                                                                    "N/A"
                                                                    }
                                                                </Box>
                                                            </Td>
                                                            {filteredPeriods.map((period, qIdx) => (
                                                                <Td key={qIdx} className={Styles.td}>
                                                                    <Box maxW="200px" whiteSpace="pre-wrap">
                                                                        {indicator.value.find((v) => v.type === "actuals" && v.period === period)?.chart_top_value?.[0]?.value || "N/A"}
                                                                    </Box>
                                                                </Td>
                                                            ))}
                                                        </Tr>
                                                    ))}
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                        </TableContainer> 
                    }
            
        </>
    )
}

export default Indicators