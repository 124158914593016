import React, { useState, useEffect, useContext } from 'react';
import { Link, useParams } from 'react-router-dom';
import Select from 'react-select';
import { EditContext } from '../../../../../../../../Context/BreadcrumbsContext';
import API from '../../../../../../../../Services/API';
import { Box, Button, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, useDisclosure } from '@chakra-ui/react';
import LoaderSpinner from '../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../../Widgets/ErrorWidget/ErrorWidget'
import { FiscalYearContext } from '../../Context/FiscalYearContext';
const FiscalYear = () => {
    const token = window.localStorage.getItem("accessToken");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { actionid } = useParams();
    const {edit} = useContext(EditContext);
    const [isLoading, setIsLoading] = useState(true);
    const [action, setAction] = useState(null);
    const [qbrData, setQbrData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [message, setMessage] = useState(null);
    const { selectedFiscalYearName, setSelectedFiscalYearName } = useContext(FiscalYearContext);
    const [selectedFiscalQtrName, setSelectedFiscalQtrName] = useState(null);
    const [noData, setNoData]  = useState(false)
    const [fiscalYearList, setFiscalYearList] = useState([]);
    const [fiscalQtrList, setFiscalQtrList] = useState([]);
    const [selectedFiscalYear, setSelectedFiscalYear] = useState(null);
    const [selectedFiscalQtr, setSelectedFiscalQtr] = useState(null);
    const [getQtrList, setGetQtrList] = useState([]);

    useEffect(() => {
        API.get(`/fiscal-year-detail`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response => {
            const yearList = response.data[0].fiscalyear.map(item => ({ value: item.id, label: item.name }));
            const quarterList = response.data[1].fiscalquarter.map(item => ({ value: item.id, label: item.name }));
            setFiscalYearList(yearList);
            setFiscalQtrList(quarterList);
        
            API.get(`/pillar?module_id=${actionid}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(pillarResponse => {
                setAction(pillarResponse.data);
                API.get(`/qbr-selected-fy?action_id=${pillarResponse.data.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                }).then(qbrResponse => {
                    setQbrData(qbrResponse.data);

                    if (qbrResponse.data[0].fiscal_year) {
                        const defaultYear = yearList.find(year => year.value === qbrResponse.data[0].fiscal_year);
                        setSelectedFiscalYear(defaultYear || null);
                        setSelectedFiscalYearName(defaultYear.label)
                    }

                    if (qbrResponse.data[0].fiscal_qtr) {
                        const defaultQtr = quarterList.find(qtr => qtr.value === qbrResponse.data[0].fiscal_qtr);
                        setSelectedFiscalQtr(defaultQtr || null);
                        setSelectedFiscalQtrName(defaultQtr.label)
                    }

                    if (selectedFiscalYear) {
                        const selectedOptionNew = `FY${selectedFiscalYear.label.slice(-2)}`;
                        let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(selectedOptionNew));
        
                        relatedQuarters = relatedQuarters.sort((a, b) => {
                            const getQuarterNumber = (item) => {
                                const parts = item.label.split(' ');
                                return parts.length > 1 ? parseInt(parts[1].replace('Q', '')) || 0 : 0;
                            };
                            return getQuarterNumber(b) - getQuarterNumber(a);
                        });
        
                        setGetQtrList(relatedQuarters);
                    }
                    setIsLoading(false)
                }).catch(error => {
                    setIsLoading(false)
                });
            }).catch(error => {
                setIsLoading(false)
            });
        }).catch(error => {
            setIsLoading(false)
        });
    }, [actionid, token, setSelectedFiscalYearName]);

    
    
    

    const customStyles = {
        control: (provided) => ({
            ...provided,
            backgroundColor: "transparent",
            color: "#ffffff",
            border: "1px solid #63b3ed",
            borderRadius: "8px",
            padding: "5px",
        }),
        placeholder: (provided) => ({
            ...provided,
            color: "#ffffff", // White placeholder color
            fontSize: "14px",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "#ffffff", // Ensures the selected value is also white
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected ? "#63b3ed" : state.isFocused ? "#63b3ed" : "#ffffff",
            color: state.isSelected ? "#ffffff" : state.isFocused ? "#ffffff" : "#333333",
            padding: "10px",
            cursor: "pointer",
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: "#f0f8ff",
            borderRadius: "8px",
        }),
    };
    const saveFiscalYear = () => {
        setMessage(null)

        // if(qbrData.length > 0 && selectedFiscalYear?.value === qbrData[0]?.fiscal_year && 
        //     (selectedFiscalQtr ? selectedFiscalQtr.value : null) === qbrData[0]?.fiscal_qtr
        // ){
        //     setMessage("No changes were made.");
        //     return; // Exit early to avoid unnecessary API call
        // }else if(qbrData.length > 0 && selectedFiscalYear?.value === qbrData[0]?.fiscal_year){
            
        // }

        const oldFiscalYear = qbrData[0]?.fiscal_year;
        const oldFiscalQtr = qbrData[0]?.fiscal_qtr;
        const newFiscalYear = selectedFiscalYear?.value;
        const newFiscalQtr = selectedFiscalQtr ? selectedFiscalQtr.value : null;
        const isFiscalYearChanged = oldFiscalYear !== newFiscalYear;
        const isFiscalQtrChanged = oldFiscalQtr !== newFiscalQtr;

        if (!isFiscalYearChanged && !isFiscalQtrChanged) {
            setMessage("No changes were made.");
            return;
        }
        
        if (qbrData.length > 0) {
            if (isFiscalYearChanged && isFiscalQtrChanged) {
                onOpen()
            } else if (isFiscalYearChanged) {
                onOpen()
            } else if (isFiscalQtrChanged) {
                setIsLoading(true)
                const body = {
                    id: qbrData[0]?.id,
                    action: action.id,
                    fiscal_year: selectedFiscalYear.value,
                    fiscal_qtr: selectedFiscalQtr ? selectedFiscalQtr.value : null,
                    "module_id": Number(actionid),
                }
                API.put(`/qbr-selected-fy`, body, {
                    headers: {
                    'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    API.get(`/qbr-selected-fy?action_id=${action.id}`,{
                        headers: {
                            'Authorization': 'Bearer ' + token
                        }
                    }).then(response=>{
                        setQbrData(response.data)
                        const selectedFiscalYear = fiscalYearList.find(year => year.id === response.data[0].fiscal_year);
                        setSelectedFiscalYearName(selectedFiscalYear && selectedFiscalYear.name)
                    }).catch((error) => {
                    })
                    setIsLoading(false)
                    setMessage('Update QTR successfully')
                }).catch(error=>{
                    setMessage(
                        error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                        error.response.data.msg ? error.response.data.msg : 'Facing technical error'
                    )
                    setIsLoading(false)
                })
                
            }
        }else{
            setIsLoading(true)
            const body = {
                action: action.id,
                fiscal_year: selectedFiscalYear.value,
                fiscal_qtr: selectedFiscalQtr ? selectedFiscalQtr.value : null,
                "module_id": Number(actionid),
            }
            API.post(`/qbr-selected-fy`, body, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                API.get(`/qbr-selected-fy?action_id=${action.id}`,{
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setQbrData(response.data)
                    const selectedFiscalYear = fiscalYearList.find(year => year.id === response.data[0].fiscal_year);
                    setSelectedFiscalYearName(selectedFiscalYear && selectedFiscalYear.name)
                }).catch((error) => {
                })
                setIsLoading(false)
                setMessage('Year succesfully created for action review')
            }).catch(error=>{
                setMessage(
                    error.response.data.non_field_errors ? error.response.data.non_field_errors[0] :
                    error.response.data.msg ? error.response.data.msg : 'Facing technical error'
                )
                setIsLoading(false)
            })
        }
    }
    const deleteandCreateNew = async () =>{
        setIsLoading(true)
        try {
            const response = await API.delete('/qbr-selected-fy', {
                data: { id: qbrData && qbrData[0].id},
                headers: {
                    'Authorization': 'Bearer ' + token, // Adding Authorization header
                },
            });
            const body = {
                action: action.id,
                fiscal_year: selectedFiscalYear.value,
                fiscal_qtr: selectedFiscalQtr ? selectedFiscalQtr.value : null,
                "module_id": Number(actionid),
            }
            API.post(`/qbr-selected-fy`, body, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then(response=>{
                setSelectedOption(null);
                setMessage('Deleted old data and updated new data with selected year')
                API.get(`/qbr-selected-fy?action_id=${action.id}`,{
                    headers: {
                      'Authorization': 'Bearer ' + token
                    }
                }).then(response=>{
                    setQbrData(response.data)
                    const selectedFiscalYear = fiscalYearList.find(year => year.id === response.data[0].fiscal_year);
                    setSelectedFiscalYearName(selectedFiscalYear && selectedFiscalYear.name)
                    setIsLoading(false)
                    window.location.hash = "qbr";
                    window.location.reload();
                }).catch(error=>{
                    setIsLoading(false)
                })
            }).catch(error=>{
                setIsLoading(false)
            })
            onClose();
        } catch (error) {
            setIsLoading(false)
        } finally {
            setIsLoading(false);
        }
    }

    const handleFiscalQtrChange = (selectedOption) => {
        setSelectedFiscalQtr(selectedOption);
    };

    const sortedFiscalYearList = fiscalYearList.sort((a, b) => {
      const getFiscalYear = (label) => {
          const match = label.match(/FY(\d+)/);
          return match ? parseInt(match[1], 10) : -1;
      };
      const getQuarter = (label) => {
          const match = label.match(/Q(\d+)/);
          return match ? parseInt(match[1], 10) : 0; // Default 0 for no quarter
      };
      const fiscalYearDiff = getFiscalYear(b.label) - getFiscalYear(a.label);
      if (fiscalYearDiff !== 0) return fiscalYearDiff;
      return getQuarter(b.label) - getQuarter(a.label);
    });

    const handleFiscalYearChange = (selectedOption) => {
        setSelectedFiscalYear(selectedOption);
        setSelectedFiscalQtr(null);
        setGetQtrList([]);
        if(selectedOption) { // Check if selectedOption is not null
            const SelectedOptionNew = `FY${selectedOption.label.slice(-2)}`;
            let relatedQuarters = fiscalQtrList.filter(item => item.label.includes(SelectedOptionNew));
            relatedQuarters = relatedQuarters.sort((a, b) => {
                const getQuarterNumber = (item) => {
                    if (!item?.label) return 0; // Default value if label is missing
                    const parts = item.label.split(' ');
                    return parts.length > 1 ? parseInt(parts[1].replace('Q', '')) || 0 : 0;
                };
            
                return getQuarterNumber(b) - getQuarterNumber(a);
            });
            setGetQtrList(relatedQuarters);
            if (selectedOption.label === selectedFiscalYear) {
              const defaultQtr = fiscalQtrList.find(qtr => qtr.label === selectedFiscalQtr);
              setSelectedFiscalQtr(defaultQtr || null);
            }
          } else {
              // If selectedOption is null, reset related quarters
              setGetQtrList([]);
          }
    }


    return (
        <>
            {isLoading ? <LoaderSpinner /> : null }
            {message && <ErrorWidget message={message} />}
            <Box style={{display: "flex",alignItems: "center",gap: "10px",}}>
                {edit ? 
                    <>
                        <Select
                            id={'metricData_targetfiscalyear'}
                            options={sortedFiscalYearList}
                            value={selectedFiscalYear}
                            onChange={handleFiscalYearChange}
                            placeholder="Select fiscal year"
                            styles={customStyles}
                        />
                        {/* <Select
                            options={sortedFiscalYearList}
                            //value={selectedOption}
                            onChange={handleChange}
                            placeholder="Select a Fiscal Year"
                            styles={customStyles}
                            //defaultValue={fiscalYearOptions.find(option => option.label === selectedFiscalYearName)}
                            value={selectedFiscalYear}
                        /> */}
                        <Select
                            id={'metricData_targetfiscalquarter'}
                            options={selectedFiscalYear ? getQtrList : []}
                            value={selectedFiscalQtr}
                            onChange={handleFiscalQtrChange}
                            placeholder={selectedFiscalYear ? "Select fiscal quarter" : "Select fiscal year first"}
                            isDisabled={!selectedFiscalYear} // Disable if no fiscal year is selected
                            isClearable // Enable clearable option
                            styles={customStyles}
                        />
                        {/* <Select
                            options={selectedFiscalYearName? getQtrList : []}
                            onChange={handleFiscalQtrChange}
                            placeholder={selectedFiscalYearName ? "Select fiscal quarter" : "Select fiscal year first"}
                            styles={customStyles}
                            defaultValue={getQtrList ? getQtrList.find(option => option.label === selectedFiscalQtrName) : null}
                            isClearable
                        /> */}
                        <Button 
                            borderColor={'#63b3ed'} 
                            color={'#ffffff'}  
                            variant='outline' 
                            _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}} 
                            onClick={saveFiscalYear}
                        > Save </Button>
                    </>: 
                    <Box> 
                        {
                            noData ? 
                            <>
                                <Box float={'left'}>
                                    <Select
                                        id={'metricData_targetfiscalyear'}
                                        options={sortedFiscalYearList}
                                        value={selectedFiscalYear}
                                        onChange={handleFiscalYearChange}
                                        placeholder="Select fiscal year"
                                        styles={customStyles}
                                    />
                                </Box>
                                <Box float={'left'}>
                                    <Select
                                        id={'metricData_targetfiscalquarter'}
                                        options={selectedFiscalYear ? getQtrList : []}
                                        value={selectedFiscalQtr}
                                        onChange={handleFiscalQtrChange}
                                        placeholder={selectedFiscalYear ? "Select fiscal quarter" : "Select fiscal year first"}
                                        isDisabled={!selectedFiscalYear} // Disable if no fiscal year is selected
                                        isClearable // Enable clearable option
                                        styles={customStyles}
                                    />
                                </Box>
                                <Box float={'left'} mt={'5px'} ml={'5px'}>
                                    <Button 
                                        borderColor={'#63b3ed'} 
                                        color={'#ffffff'}  
                                        variant='outline' 
                                        _hover={{backgroundColor: "#63b3ed", color: "#ffffff",}} 
                                        onClick={saveFiscalYear}
                                    > Save </Button>
                                </Box>
                            </> : <>{selectedFiscalYearName} {selectedFiscalQtrName && selectedFiscalQtrName.replace(/FY\d{2}\s*/, "")} Action Review </>
                        }
                   
                    </Box> 
                }
            </Box>
            <Modal isOpen={isOpen} onClose={onClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Data Reset Confirmation</ModalHeader>
                    <ModalBody>
                        Are you sure you want to delete all your saved data? <br /> 
                        This action is irreversible.
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button mr={3} onClick={onClose}>
                            Close
                        </Button>
                        <Button colorScheme="blue" mr={3} onClick={deleteandCreateNew}>
                            Delete and create new
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}
export default FiscalYear