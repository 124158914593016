import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom';
import API from '../../../../../../Services/API'
import SlideOne from './SlideOne/SlideOne'
import SlideTwo from './SlideTwo/SlideTwo'
import SlideThree from './SlideThree/SlideThree';
import SlideFour from './SlideFour/SlideFour';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import GeneratePdf from './GeneratePdf/GeneratePdf';
import { FiscalYearProvider } from './Context/FiscalYearContext';
import { RepeatIcon } from "@chakra-ui/icons";

const Qbr = () => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [action, setAction] = useState(null);
    const [selectedFiscalYearID, setSelectedFiscalYearID] = useState(null);
    const [slide2Status, setSlide2Status] = useState('')
    const [slide3Status, setSlide3Status] = useState('')
    const [slide4Status, setSlide4Status] = useState('')
    const [screenOneStatus, setScreenOneStatus] = useState(null)
    const [screenTwoStatus, setScreenTwoStatus] = useState(null)

    useEffect(()=>{
        setAction(null);
        setSelectedFiscalYearID(null);
        API.get(`/pillar?module_id=${actionid}`, {
          headers: {
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
            setAction(response.data);
            API.get(`/qbr-screen-status?screen_no=1&action_id=${response.data.id}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then((response) => {
                setScreenOneStatus(response.data[0].approval_status)
            }).catch(error=>{
            })
            
            API.get(`/qbr-screen-status?screen_no=2&action_id=${response.data.id}`, {
              headers: {
                'Authorization': 'Bearer ' + token
              }
            }).then((response) => {
                setScreenTwoStatus(response.data[0].approval_status)
            }).catch(error=>{
            })
              

            API.get(`/qbr-selected-fy?action_id=${response.data.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then((response) => {
                setSelectedFiscalYearID(response?.data[0]?.id)
            }).catch(error=>{
            })
        }).catch((error) => {
        });
    }, [token, actionid])

    const getSlide2Status = (status) =>{
        setSlide2Status(status)
        console.log("setSlide2Status", status)
    }
    const getSlide3Status = (status) =>{
        setSlide3Status(status)
        console.log("setSlide3Status", status)
    }
    const getSlide4Status = (status) =>{
        setSlide4Status(status)
        console.log("setSlide4Status", status)
    }

    const onReload = () => {
        window.location.hash = "qbr";
        window.location.reload();
    }

    return (
        <FiscalYearProvider>
            <Box>
                {/* <Box float={'left'} mb={'10px'}>
                    <Tooltip label="If you don't see the latest changes, please click here." aria-label="If you don't see the latest changes, please click here.">
                        <IconButton
                            aria-label="If you don't see the latest changes, please click here."
                            icon={<RepeatIcon />}
                            onClick={onReload}
                            colorScheme="blue"
                        />
                    </Tooltip>
                </Box> */}
                {
                    //slide2Status === 'Approved' && 
                    //slide3Status === 'Approved' && 
                    slide4Status === 'Approved' &&
                    screenOneStatus === 'Approved' &&
                    screenTwoStatus === 'Approved' ? 
                    <GeneratePdf /> : null
                }
                <SlideOne />
                <SlideTwo getSlide2Status={getSlide2Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
                <SlideThree getSlide3Status={getSlide3Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
                <SlideFour getSlide4Status={getSlide4Status} selectedFiscalYearID={selectedFiscalYearID} action={action} />
            </Box>
        </FiscalYearProvider>
        
    )
}

export default Qbr