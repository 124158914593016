import React, { useState, useEffect, useRef, useMemo, useContext } from "react";
import { Link } from "react-router-dom";
import Styles from "./impactgalleryitems.module.css";
import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Box,
  Select,
  Text,
} from "@chakra-ui/react";
import {
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from "@chakra-ui/react";
import { EditIcon, DeleteIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import API from "../../../Services/API";
import { useDisclosure } from "@chakra-ui/react";
import LoaderSpinner from "../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import { capitalize } from "@mui/material";
import { IconButton, Tooltip } from "@chakra-ui/react";
import ErrorWidget from "../../../Components/Widgets/ErrorWidget/ErrorWidget";
import { EditContext } from "../../../Context/BreadcrumbsContext";

const ImpactGalleryItems = () => {
  const token = window.localStorage.getItem("accessToken");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = useState();
  const [blogList, setBlogList] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [complianceErrorMessage, setComplianceErrorMessage] = useState();
  const [selectedCompliance, setSelectedCompliance] = useState({status:null,id:null});
  const [hasPermit, setHasPermit] = useState(false);
  const { user } = useContext(EditContext);

  useEffect(() => {
    document.documentElement.scrollTo(0, 0);
    setIsLoading(true);
    const hasPermitToSeeAllheader = user?.comms_user || user?.action_office_user || user?.is_superuser;
    setHasPermit(hasPermitToSeeAllheader)
    API.get(`/mytask-blog`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        setIsLoading(false);
        setBlogList(response.data);
      })
      .catch((error) => {
        setErrorMessage(error?.response?.data?.msg);
        setIsLoading(false);
      });
  }, []);
  const approveBlog = (event) => {
    setComplianceErrorMessage({});
    setIsLoading(true);
    const formData = new FormData();
    if (
      event.compliance_status === null &&
      selectedCompliance?.status === null
    ) {
      setComplianceErrorMessage({
        id: event.id,
        message: "Please select a Compliance status before approving.",
      });
      setIsLoading(false);
      return;
    }

    
    if (selectedCompliance.status === "Select compliance") {
      setComplianceErrorMessage({
        id: event.id,
        message: "Please select a Compliance status before approving.",
      });
      setIsLoading(false);
      return;
    }

    formData.append("blog_id", parseInt(event.id));
    formData.append("approval_status", "approved");
    if (
      selectedCompliance?.id &&
      selectedCompliance?.status &&
      event.id == selectedCompliance?.id
    ) {
      
      formData.append("compliance_status", selectedCompliance.status);
    } else {
      
      formData.append("compliance_status", event.compliance_status);
    }
    // return;
    API.put(`/blog`, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        API.get(`/mytask-blog`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            setIsLoading(false);
            setBlogList(response.data);
            setErrorMessage("Blog approved successfully");
          })
          .catch((error) => {
            setErrorMessage(error?.response?.data?.msg);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const rejectBlog = (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("blog_id", parseInt(event.target.id));
    formData.append("approval_status", "reject");
    API.put(`/blog`, formData, {
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((response) => {
        API.get(`/mytask-blog`, {
          headers: {
            Authorization: "Bearer " + token,
          },
        })
          .then((response) => {
            setIsLoading(false);
            setBlogList(response.data);
            setErrorMessage("Blog rejected successfully");
          })
          .catch((error) => {
            setErrorMessage(error?.response?.data?.msg);
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const complianceOptions = [
    {
      id: 1,
      name: "Select compliance",
    },
    {
      id: 2,
      name: "The subject matter in this content requires content compliance review and has gone through the content compliance review process.",
    },
    {
      id: 3,
      name: "The subject matter in this content does not require content compliance review.",
    },
  ];

  const handleChange = (event, blogId) => {
    setSelectedCompliance({status:null,id:null})
    const selectedValue = event.target.value;
    setSelectedCompliance({ status: selectedValue, id: blogId });
  };


  return (
    <>
      {isLoading ? <LoaderSpinner /> : null}
      {errorMessage && errorMessage ? (
        <ErrorWidget message={errorMessage && errorMessage} />
      ) : null}
      {blogList && blogList.length > 0 ? (
        <TableContainer>
          <Table size="sm">
            <Thead>
              <Tr className={Styles.tr}>
                <Th className={Styles.th}>Author</Th>
                <Th className={Styles.th}>Title</Th>
                <Th className={Styles.th}>Pillars</Th>
                <Th className={Styles.th}>Actions</Th>
                <Th className={Styles.th}>Regions</Th>
                <Th className={Styles.th}>Countries</Th>
                {hasPermit && <Th className={Styles.th}>Compliance status</Th>}
                <Th className={Styles.th}>Approval status</Th>
                {hasPermit && <Th className={Styles.th}>Approve</Th>}
              </Tr>
            </Thead>
            <Tbody textTransform={"capitalize"}>
              {blogList &&
                blogList.map((blog) => (
                  <Tr>
                    <Td>
                      <Box className={Styles.tdBox} w={"115px"}>
                        <Text
                          // to={`viewpost/${blog.id}/${blog.title}`}
                          title={blog.Authorization}
                        >
                          {blog.author}
                        </Text>
                      </Box>
                    </Td>
                    <Td>
                      <Box className={Styles.tdBox} w={"115px"}>
                        <Text
                          // to={`viewpost/${blog.id}/${blog.title}`}
                          title={blog.Authorization}
                        >
                          {blog.title}
                        </Text>
                      </Box>
                    </Td>
                    <Td>
                      <Box className={Styles.tdBox} w={"120px"}>
                        <UnorderedList>
                          {blog.blog_pillar &&
                            blog.blog_pillar.map((pillar) => (
                              <ListItem>
                                <Text
                                  // to={`pillar/${pillar.module}/${pillar.name}`}
                                >
                                  {pillar.name}
                                </Text>
                              </ListItem>
                            ))}
                        </UnorderedList>
                      </Box>
                    </Td>
                    <Td>
                      <Box className={Styles.tdBox} w={"115px"}>
                        <UnorderedList>
                          {blog.blog_action &&
                            blog.blog_action.map((action) => (
                              <ListItem>
                                <Link
                                  to={`action/${action.module}/${action.name}#impactgallery`}
                                >
                                  {action.name}
                                </Link>
                              </ListItem>
                            ))}
                        </UnorderedList>
                      </Box>
                    </Td>
                    <Td>
                      <Box className={Styles.tdBox}>
                        <UnorderedList>
                          {blog.blog_region &&
                            blog.blog_region.map((region) => (
                              <ListItem>
                                <Text>{region.name}</Text>
                              </ListItem>
                            ))}
                        </UnorderedList>
                      </Box>
                    </Td>
                    <Td>
                      <Box className={Styles.tdBox}>
                        <UnorderedList>
                          {blog.blog_country &&
                            blog.blog_country.map((country) => (
                              <ListItem>
                                <Text>{country.name}</Text>
                              </ListItem>
                            ))}
                        </UnorderedList>
                      </Box>
                    </Td>

                  {hasPermit &&  <Td>
                      <Box className={Styles.tdBox} w="100%">
                        <Select
                          className={Styles.selectDropdown_text}
                          onChange={(e) => handleChange(e, blog.id)}
                          defaultValue={
                            complianceOptions.find(
                              (option) => option.name === blog.compliance_status
                            )?.name
                          }
                        >
                          {blog.compliance_status != null
                            ? complianceOptions.slice(1).map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))
                            : complianceOptions.map((option) => (
                                <option key={option.id} value={option.name}>
                                  {option.name}
                                </option>
                              ))}
                        </Select>
                        {complianceErrorMessage?.id === blog.id && (
                          <Text
                            className={Styles.selectDropdown_text}
                            textTransform={"none"}
                            textAlign={"center"}
                            color={"red"}
                          >
                            {complianceErrorMessage.message}
                          </Text>
                        )}
                      </Box>
                    </Td>}
                    <Td>
                      <Box className={Styles.tdBox}>{blog.approval_status}</Box>
                    </Td>
                  {hasPermit &&  <Td id={blog.id} className="actionButton">
                      <Tooltip
                        id={blog.id}
                        label="Approve Blog"
                        aria-label="Approve Blog Tooltip"
                      >
                        <IconButton
                          variant="outline"
                          colorScheme="teal"
                          aria-label="Call Sage"
                          fontSize="14px"
                          icon={<CheckIcon id={blog.id} />}
                          className={`${Styles.approve} ${Styles.hoverEffectApprove}`}
                          id={blog.id}
                          // isDisabled={!complianceOptions.some(a => a.name === blog.compliance_status)}
                          onClick={() => approveBlog(blog)}
                          backgroundColor={"none"}
                        />
                      </Tooltip>
                      <Tooltip
                        id={blog.id}
                        label="Reject Blog"
                        aria-label="Reject Blog Tooltip"
                      >
                        <IconButton
                          variant="outline"
                          colorScheme="teal"
                          aria-label="Call Sage"
                          fontSize="14px"
                          icon={<CloseIcon id={blog.id} />}
                          className={`${Styles.reject} ${Styles.hoverEffectReject}`}
                          id={blog.id}
                          onClick={rejectBlog}
                        />
                      </Tooltip>
                    </Td>}
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      ) : (
        <Box> There is no Impact gallery items </Box>
      )}
    </>
  );
};

export default ImpactGalleryItems;
