import React from "react";
import PdfViewer from "./PdfViewer";
import WordViewer from "./WordViewer";
import VideoViewer from "./VideoViewer";
import { Box } from "@chakra-ui/react";
import VideourlViewer from "./VideourlViewer";
import DocumentUrlViewer from "./DocUrlViewer";

const UnifiedFileViewer = ({ fileUrl, fileType,url }) => {
  // Extract the file extension from the URL

  // Switch case to render based on file type
  const renderFileViewer = () => {
    const fileExtension =
      fileUrl && fileUrl.includes(".pdf")
        ? "pdf"
        : fileUrl && fileUrl.includes(".mp4")
        ? "video"
        : (fileUrl && fileUrl.includes(".docx")) ||
          (fileUrl && fileUrl.includes(".doc"))
        ? "word"
        : fileType === "video"
        ? "urlvideo"
        : fileType === "document"
        ? "urldoc"
        : "null";
        
        if(fileExtension==="null") return;
        switch (fileExtension) {
      case "pdf":
        return (
          <PdfViewer
            pdfUrl={fileUrl}
          />
        );
      case "word":
        return (
          <WordViewer
            fileUrl={fileUrl}
          />
        );
      case "video":
        return <VideoViewer fileUrl={fileUrl} />;
      case "urlvideo":
        return <VideourlViewer videoUrl={url} />;
      case "urldoc":
        return <DocumentUrlViewer fileUrl={url} />;
      default:
        return <div>Unsupported file type</div>;
    }
  };

  return <Box sx={{ height: window.innerHeight }}>{renderFileViewer()} </Box>;
};

export default UnifiedFileViewer;
