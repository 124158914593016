import React, { useState, useRef } from 'react';
import $ from 'jquery'
import API from '../../../../../Services/API';
import Styles from '../../notifications.module.css';
import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';
import { Box, Button, FormControl, FormLabel, IconButton, Input, SimpleGrid, Tooltip, useDisclosure } from '@chakra-ui/react';
import { AddIcon, EditIcon } from '@chakra-ui/icons';
import JoditEditor from 'jodit-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';


const EditNotification = ({id, notification, getNotification}) => {
    const token = window.localStorage.getItem("accessToken")
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [state, setState] = useState({ isPaneOpen: false });
    const [isLoading, setIsLoading] = useState(false)
    const [recipients, setRecipients] = useState([]);
    const [subject, setSubject] = useState(notification.subject);
    const [errors, setErrors] = useState({});
    const editor = useRef(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTime, setSelectedTime] = useState(null);
    const [message, setMessage] = useState(null)

    const openSlidingPane = () => {
        setIsLoading(true)
        setState({ isPaneOpen: true });
        resetForm()
        setSubject(notification.subject)
        document.body.style.position = 'fixed';
        document.body.style.width = '100%';
        API.get(`/list-user`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            //const filteredRecipients = response.data.filter(user => user.first_name && user.last_name);
            const filteredRecipients = response.data.filter(user => user.first_name && user.last_name).sort((a, b) => `${a.first_name} ${a.last_name}`.localeCompare(`${b.first_name} ${b.last_name}`))
            setRecipients(filteredRecipients)
            API.get(`custom-notification?id=${id}`, {
                headers: {
                  'Authorization': 'Bearer ' + token
                }
            }).then((selectedNotification) => {

                const date = new Date(selectedNotification?.data[0]?.notf_delivry_tm);
                const formattedDate = date instanceof Date && !isNaN(date)? date.toLocaleDateString('en-US'): '';
                setSelectedDate(date)
                const parsedTime = new Date(selectedNotification?.data[0]?.notf_delivry_tm);
                setSelectedTime(parsedTime);


                setRecipients((prevRecipients) =>
                    prevRecipients.map((recipient) =>
                        recipient.id === selectedNotification?.data[0]?.user ? { ...recipient, selected: !recipient.selected } : recipient
                    )
                );
            }).catch(error => {
                
            });
            setIsLoading(false)
        }).catch(error => {
        });
    };

    const closeSlidingPane = () => {
        setState({ isPaneOpen: false });
        document.body.style.position = 'revert';
        document.body.style.width = '100%';
        setErrors({});
    };

    const toggleSelection = (id) => {
        setRecipients((prevRecipients) =>
            prevRecipients.map((recipient) =>
                recipient.id === id ? { ...recipient, selected: !recipient.selected } : recipient
            )
        );
    };

    const validateFields = () => {
        const newErrors = {};
        const descText = $("#descriptionNotification").val()
        if (!subject) {
            newErrors.subject = "Subject is required!";
        }

        if(descText === "<p><br></p>"){
            newErrors.body = "Notification body cannot be empty!";
        }

        if (!selectedDate) {
            newErrors.date = "Delivery date is required!";
        }

        if (!selectedTime) {
            newErrors.time = "Delivery time is required!";
        }

        const selectedRecipients = recipients.filter((r) => r.selected);
        if (selectedRecipients.length === 0) {
            newErrors.recipients = "Please select at least one recipient!";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        setMessage(null);
        if (!validateFields()) {
            return;
        }
    
        setIsLoading(true);
    
        const deliveryDateTime = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            selectedTime.getHours(),
            selectedTime.getMinutes(),
            selectedTime.getSeconds()
        ).toISOString();
        const now = new Date();
        const immediateAlert = Math.abs(new Date(deliveryDateTime) - now) < 60000; // Within 1 minute
    
        const selectedRecipients = recipients.filter((r) => r.selected);
    
        try {
            // Iterate over each recipient and send a request
            await Promise.all(
                selectedRecipients.map((recipient) => {
                    const payload = {
                        user: recipient.id,
                        subject,
                        message: $("#descriptionNotification").val(),
                        notification_delivery_time: deliveryDateTime,
                        immediate_alert: immediateAlert,
                        id: id
                    };
    
                    // Send a PUT request for each recipient
                    return API.put(`/custom-notification`, payload, {
                        headers: { Authorization: `Bearer ${token}` },
                    });
                })
            );
    
            // After all requests are successful
            getNotification();
            closeSlidingPane();
            setMessage('Notifications updated successfully');
            setIsLoading(false);
        } catch (error) {
            console.error("Error submitting data:", error);
        } finally {
            setIsLoading(false);
        }
    };
    

    const resetForm = () => {
        setSubject('');
        $("#descriptionNotification").val('')
        setSelectedDate(null);
        setSelectedTime(null);
        setRecipients(recipients.map(r => ({ ...r, selected: false })));
    };

    const config = {
        readonly: false,
        placeholder: "Type something amazing...",
        toolbarSticky: true,
        buttons: ["bold", "italic", "underline", "strikethrough", "link", "ul", "ol"],
        height: 200, // Set fixed height
    };

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {message && message ? <ErrorWidget message={message} /> : null}
            <Tooltip id={id} label="Edit Notification" aria-label="Edit Notification" onClick={openSlidingPane}> 
                <IconButton
                    variant='solid'
                    aria-label='Done'
                    fontSize='15px'
                    icon={<EditIcon />}
                    ml={'5px'}
                    className={Styles.edit} id={id}
                    onClick={openSlidingPane}
                />
            </Tooltip>
            <SlidingPane
                className={Styles.slidingPane}
                isOpen={state.isPaneOpen}
                title="Edit Notification"
                width="50%"
                onRequestClose={closeSlidingPane}
            >
                <Box>
                    <FormControl mb={'15px'} isInvalid={errors.recipients}>
                        <FormLabel>Notification Recipients</FormLabel>
                        <Box
                            border={'1px solid #ddd'}
                            borderRadius={'4px'}
                            maxH={'150px'}
                            overflowY={'auto'}
                            padding={'8px'}
                        >
                            {recipients.map((recipient) => (
                                <div
                                    key={recipient.id}
                                    onClick={() => toggleSelection(recipient.id)}
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        marginBottom: "8px",
                                        cursor: "pointer",
                                    }}
                                >
                                    {recipient.selected && (
                                        <span
                                            style={{
                                                color: "green",
                                                marginRight: "8px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            ✔
                                        </span>
                                    )}
                                    <span>{recipient.first_name} {recipient.last_name}</span>
                                </div>
                            ))}
                        </Box>
                        {errors.recipients && <Box color="red.500">{errors.recipients}</Box>}
                    </FormControl>
                    <FormControl mb={'15px'} isInvalid={errors.subject}>
                        <FormLabel>Subject</FormLabel>
                        <Input
                            defaultValue={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            borderColor={errors.subject ? 'red.500' : undefined}
                        />
                        {errors.subject && <Box color="red.500">{errors.subject}</Box>}
                    </FormControl>
                    <FormControl mb={'15px'} isInvalid={errors.body}>
                        <FormLabel>Notification Body</FormLabel>
                        <JoditEditor
                            id={"descriptionNotification"}
                            ref={editor}
                            tabIndex={1}
                            style={{ height: '200px' }}
                            config={config}
                            value={notification.msg}
                        />
                        {errors.body && <Box color="red.500">{errors.body}</Box>}
                    </FormControl>
                    <SimpleGrid columns={2} spacing={10}>
                        <FormControl isInvalid={errors.date}>
                            <FormLabel>Delivery Date</FormLabel>
                            <DatePicker
                                selected={selectedDate}
                                onChange={(date) => {
                                    setSelectedDate(date);
                                    setErrors({ ...errors, date: undefined });
                                }}
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()}
                                placeholderText="MM/DD/YYYY"
                                customInput={
                                    <Input 
                                        borderColor="gray.300" 
                                        focusBorderColor="blue.500" 
                                        defaultValue={selectedDate ? new Date(selectedDate).toLocaleDateString('en-CA') : ''}
                                    />
                                }
                            />
                            <Button mt={2} width="auto" size="sm" colorScheme="blue" onClick={() => setSelectedDate(new Date(new Date().setHours(0, 0, 0, 0)))}>Select current date</Button>
                            {errors.date && <Box color="red.500">{errors.date}</Box>}
                        </FormControl>
                        <FormControl isInvalid={errors.time}>
                            <FormLabel>Delivery Time (PST Time Zone)</FormLabel>
                            <DatePicker
                                selected={selectedTime}
                                onChange={(time) => {
                                    setSelectedTime(time);
                                    setErrors({ ...errors, time: undefined });
                                }}
                                showTimeSelect
                                showTimeSelectOnly
                                timeIntervals={1}
                                timeCaption="Time"
                                dateFormat="HH:mm:ss"
                                placeholderText="Select Time (HH:MM:SS)"
                                customInput={
                                    <Input
                                        value={
                                            selectedTime
                                                ? selectedTime.toLocaleTimeString('en-GB', {
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                    second: '2-digit',
                                                })
                                                : ''
                                        }
                                        placeholder="00:00:00"
                                        borderColor={errors.time ? 'red.500' : undefined}
                                        focusBorderColor="#3182ce"
                                    />
                                }
                            />
                            <Button
                                mt={2}
                                width="auto"
                                size="sm"
                                colorScheme="blue"
                                onClick={() => setSelectedTime(new Date())}
                            >
                                Select Current Time
                            </Button>
                            {errors.time && <Box color="red.500">{errors.time}</Box>}
                        </FormControl>
                    </SimpleGrid>
                    <Button
                        mt={'20px'}
                        colorScheme="blue"
                        onClick={handleSubmit}
                    >
                        Send Notification
                    </Button>
                </Box>
            </SlidingPane>
        </>
    );
};

export default EditNotification;
