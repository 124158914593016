import React, { useContext, useState } from "react";
import {
  Box,
  Text,
  Select,
  Button,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Stack,
  useToast,
} from "@chakra-ui/react";
import * as XLSX from "xlsx";
import { DownloadIcon } from "@chakra-ui/icons";
import API from "../../../../Services/API";
import LoaderSpinner from "../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import { EditContext } from "../../../../Context/BreadcrumbsContext";

const RadioTabFour = () => {
  const token = window.localStorage.getItem("accessToken");
  const [startMonth, setStartMonth] = useState("");
  const [startYear, setStartYear] = useState("");
  const [endMonth, setEndMonth] = useState("");
  const [endYear, setEndYear] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { hasAllPermission } = useContext(EditContext);
  
  const toast = useToast(); 

  const months = [
    { id: 1, name: "January" },
    { id: 2, name: "February" },
    { id: 3, name: "March" },
    { id: 4, name: "April" },
    { id: 5, name: "May" },
    { id: 6, name: "June" },
    { id: 7, name: "July" },
    { id: 8, name: "August" },
    { id: 9, name: "September" },
    { id: 10, name: "October" },
    { id: 11, name: "November" },
    { id: 12, name: "December" },
  ];
  ;

  const years = Array.from(
    { length: 30 },
    (_, i) => new Date().getFullYear() - i
  ).reverse();

  const getImpactStoriesReport = async () => {
    setIsLoading(true);
    try {
      const selectedMonth = startMonth;
      const selectedYear = startYear;

      const response = await API.get(
        `/mau?month=${selectedYear}-${selectedMonth}`,
        {
          headers: {
            Authorization: "Bearer " + token,
          },
        }
      );

      const data = response.data;
      if (response?.data?.length === 0) {
        toast({
          title: "Oops! No data available for the selected date range. Please change the selection.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        setIsLoading(false);
        setIsPopupOpen(false);
        return;
      }

      toast({
        title: "Report fetched successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      // Remove underscores from header names and adjust styles
      const processHeaders = (data) => {
        const headers = Object.keys(data[0]);
        const formattedHeaders = headers.map(
          (header) => header.replace(/_/g, " ") // Replace underscores with spaces
        );

        // Create a new array with formatted headers
        const newData = data.map((row) =>
          Object.fromEntries(
            headers.map((header, index) => [
              formattedHeaders[index],
              row[header],
            ])
          )
        );

        return { formattedHeaders, newData };
      };

      // Format headers and data
      const { formattedHeaders, newData } = processHeaders(data);

      // Create a worksheet from the data
      const ws = XLSX.utils.json_to_sheet(newData);

      // Dynamically calculate column widths
      const colWidths = formattedHeaders.map((col) => {
        const maxLength = Math.max(
          ...newData.map((row) => (row[col] ? row[col].toString().length : 0)),
          col.length // Include column header length
        );
        return { wch: maxLength + 2 }; // Add padding to width
      });
      ws["!cols"] = colWidths; // Apply column widths to worksheet

      // Apply header styling
      formattedHeaders.forEach((header, colIndex) => {
        const cellAddress = XLSX.utils.encode_cell({ r: 0, c: colIndex }); // Header cell address
        ws[cellAddress].s = {
          fill: { fgColor: { rgb: "000000" } }, // Black background
          font: {
            color: { rgb: "FFFFFF" },
            bold: true,
            sz: 12,
            name: "Arial",
            family: 2,
          }, // White, bold text with font size
          alignment: { horizontal: "center", vertical: "center" }, // Center alignment
        };

        // Ensure text is in uppercase
        if (ws[cellAddress].v) {
          ws[cellAddress].v = ws[cellAddress].v.toUpperCase();
        }
      });

      // Create a new workbook and append the worksheet
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "MAU Report");
      XLSX.writeFile(wb, "MAU_report.xlsx");
      setStartMonth("")
      setStartYear("")
      setIsLoading(false);
      setIsPopupOpen(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setIsLoading(false);
      setIsPopupOpen(false);
    }
  };

  const handleDownloadReport = () => {
    if (!startMonth || !startYear ) {
    // if (!startMonth || !startYear || !endMonth || !endYear) {
      setErrorMessage("All fields are required.");
      return;
    }
    setErrorMessage("");
    setIsPopupOpen(true);
  };

  return (
    <Box mt="0" paddingInline="18">
      {isLoading && <LoaderSpinner />}
      <Box p="4" borderWidth="1px" borderRadius="md">
        <Text fontSize="md" mb={0}>
        The summary report provides a monthly analysis of user activity for the Digital Solution, highlighting the number of distinct and returning users per month, along with the average number of visits per CEC.

        </Text>
      </Box>

      <Stack direction="row" pt={3} spacing={4}>
        <FormControl isRequired>
          <FormLabel>Select Month</FormLabel>
          <Select
            placeholder="Select Month"
            value={startMonth}
            onChange={(e) => setStartMonth(e.target.value)}
          >
            {months.map((month) => (
              <option key={month} value={month.id}>
                {month.name}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Select Year</FormLabel>
          <Select
            placeholder="Select Year"
            value={startYear}
            onChange={(e) => setStartYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl>

        {/* <FormControl isRequired>
          <FormLabel>End Month</FormLabel>
          <Select
            placeholder="Select Month"
            value={endMonth}
            onChange={(e) => setEndMonth(e.target.value)}
          >
            {months.map((month) => (
              <option key={month} value={month}>
                {month}
              </option>
            ))}
          </Select>
        </FormControl> */}

        {/* <FormControl isRequired>
          <FormLabel>End Year</FormLabel>
          <Select
            placeholder="Select Year"
            value={endYear}
            onChange={(e) => setEndYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year} value={year}>
                {year}
              </option>
            ))}
          </Select>
        </FormControl> */}

        <FormControl sx={{ display: "flex", alignItems: "flex-end" }}>
          <Button
            sx={{
              backgroundColor: "#00a0da",
              _hover: {
                backgroundColor: "blue.100",
              },
            }}
            leftIcon={<DownloadIcon />}
            colorScheme="blackAlpha"
            size="md"
            onClick={handleDownloadReport}
          >
            Download XLS Report
          </Button>
        </FormControl>
      </Stack>

      {errorMessage && (
        <Box mt={2} color="red.500">
          <Text>{errorMessage}</Text>
        </Box>
      )}

      <Modal isOpen={isPopupOpen} onClose={() => setIsPopupOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Download Report</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {isLoading ? (
              <VStack>
                <Text mt={4}>Fetching data... Please wait.</Text>
              </VStack>
            ) : (
              <Text>
               The report has been generated successfully. Please click “Download” to proceed.

              </Text>
            )}
          </ModalBody>
          <ModalFooter
            sx={{ background: "transparent", justifyContent: "space-around" }}
          >
            {!isLoading && (
              <>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={() => setIsPopupOpen(false)}
                >
                Cancel
              </Button>
              <Button
                sx={{ background: "#000", color: "#fff" }}
                onClick={getImpactStoriesReport}
                >
                Download
              </Button>
                </>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default RadioTabFour;
