import React, { useEffect, useState, useContext } from 'react';
import Styles from '../qbr.module.css'
import { Box, Text, Spinner, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react';
import API from '../../../../Services/API';
import { Link } from 'react-router-dom';
import parse from "html-react-parser";
import { EditContext } from '../../../../Context/BreadcrumbsContext';
import { TabContainer } from 'react-bootstrap';
import ApprooveComponent from './ApprooveComponent/ApprooveComponent';
import RejectComponent from './RejectComponent/RejectComponent';

const ScreenStatus = () => {
    const token = window.localStorage.getItem("accessToken")
    const [data, setData] = useState([])
    const { user } = useContext(EditContext);
    const [loading, setLoading] = useState(false);

    useEffect(()=>{
      getUpdatedRecords()
    },[])

    const getUpdatedRecords = ()=>{
      API.get(`/mytask-qbr-screen-status`, {
          headers: {
              'Authorization': 'Bearer ' + token
          }
      }).then(response=>{
        setData(response.data)
      }).catch(error=>{
      })
    }

    return (
        <>
            <TableContainer>
                <Table>
                    <Thead>
                        <Tr bg="gray.100">
                            <Th className={Styles.th} color="blue.600">Action</Th>
                            <Th className={Styles.th} color="blue.600">screen</Th>
                            <Th className={Styles.th} color="blue.600">Status</Th>
                            <Th className={Styles.th} color="blue.600">Updated By</Th>
                            <Th className={Styles.th} color="blue.600">Approval Status</Th>
                            <Th className={Styles.th} color="blue.600">Action</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {
                            data && data.map(item=>
                                <Tr>
                                    <Td className={Styles.td}>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}> {item.action_name} </Box>
                                    </Td>
                                    <Td className={Styles.td}>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.screen === 1 ? "Key Performance" : item.screen === 2 ?  "Progress Next Steps" : null}
                                        </Box>
                                    </Td>
                                    <Td>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.screen_status}
                                        </Box>
                                    </Td>
                                    <Td>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>
                                            {item.updated_by_name}
                                        </Box>
                                    </Td>
                                    <Td className={Styles.td}>
                                        <Box maxW={'200px'} whiteSpace={'pre-wrap'}>{item.approval_status}</Box>
                                    </Td>
                                    <Td className={Styles.td+' actionButton'}>
                                        <ApprooveComponent
                                            id={item.id}
                                            screen={item.screen}
                                            screenStatus={item.screen_status}
                                            selectedfy={item.selectedfy}
                                            action={item.action}
                                            getUpdatedRecords={getUpdatedRecords}
                                        />
                                        <RejectComponent
                                            id={item.id}
                                            screen={item.screen}
                                            screenStatus={item.screen_status}
                                            selectedfy={item.selectedfy}
                                            action={item.action}
                                            getUpdatedRecords={getUpdatedRecords}
                                        />
                                    </Td>
                                </Tr>
                            )
                        }
                    </Tbody>
                </Table>
            </TableContainer>
        </>
    )
}

export default ScreenStatus