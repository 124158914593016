import React, { useEffect, useState } from 'react';
import { Box, Button, Flex } from '@chakra-ui/react';
import Styles from './notifications.module.css'
import NotificationInbox from './NotificationInbox/NotificationInbox';
import OutgoingNotifications from './OutgoingNotifications/OutgoingNotifications';
import API from '../../../Services/API';

const Notifications = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [activeTab, setActiveTab] = useState('notificationInbox');
    const [user, setUser] = useState({});

    useEffect(()=>{
        document.documentElement.scrollTo(0, 0);
        API.get(`/user-management`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then(response=>{
            setUser(response.data)
        }).catch(error=>{
        })
    },[])

    const renderTabContent = () => {
        switch (activeTab) {
            case 'notificationInbox': return <NotificationInbox getNotificationCount={props.getNotificationCount} />
            case 'outgoingnotifications': return <OutgoingNotifications />
            default: return null;
        }
    };



    return (
        <Flex height="100vh">
            {/* Sidebar for vertical tabs */}
            <Box
                width="250px"
                borderRight="1px solid #ccc"
                padding="10px"
                backgroundColor="#f9f9f9"
            >
                <Button 
                    className={Styles.button}
                    mb={'10px'}
                    background={activeTab === 'notificationInbox' ? '#00aae0' : 'transparent'}
                    color={activeTab === 'notificationInbox' ? '#ffffff' : '#1A202C'}
                    onClick={() => setActiveTab('notificationInbox')}
                >
                    Notification Inbox
                </Button>
                {
                    user.action_office_user || user.is_superuser ? 
                    <Button
                        className={Styles.button}
                        mb={'10px'}
                        background={activeTab === 'outgoingnotifications' ? '#00aae0' : 'transparent'}
                        color={activeTab === 'outgoingnotifications' ? '#ffffff' : '#1A202C'}
                        onClick={() => setActiveTab('outgoingnotifications')}
                    >
                        Outgoing Notifications
                    </Button> : null
                }
            </Box>

            {/* Content Area */}
            <Box flex="1" padding="20px">
                {renderTabContent()}
            </Box>
        </Flex>
    );
};

export default Notifications;
