import React, { useState } from 'react';
import Styles from './import.module.css'
import API from '../../../Services/API';
import { FormControl, Heading, Card, CardBody, Box, Text, Input, Stack, StackDivider, Button, Radio, RadioGroup} from '@chakra-ui/react'
import LoaderSpinner from '../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';

const Import = () => {
    const token = window.localStorage.getItem("accessToken")
    const [selectedFile, setSelectedFile] = useState(null);
    const [value, setValue] = useState('1')
    const [isLoading, setIsloading] = useState(false);
    const [message, setMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [statusError, setStatusError] = useState(null);    

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleSubmit = async () => {
        setIsloading(true)
        const formData = new FormData();
        formData.append('file', selectedFile);
        formData.append('type', 'metric_data');
        try {
            const response = await API.post(`/upload/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + token
                }
            });
            setIsloading(false)
            setSelectedFile(null)
            setMessage(true)
        }catch (error) {
            setIsloading(false)
            setSelectedFile(null)
            setStatusError(error.response.data.metric_status)
            if(error.response.data.error !== 'Internal Server Error'){
                setErrorMessage(error.response.data.error)
            }else{
                setErrorMessage('Apologies for the inconvenience, but an error has occurred. If this issue persists, please reach out to our support team at siio-ops@cisco.com.')
            }
            
        }
    };

    return (
        <>
            {isLoading ? <LoaderSpinner /> : null}
            {message ? <Box className='colorBlue' p={'10px'}> Updated data successfully </Box>:null}
            {errorMessage !== null ? <Box className='colorRed' p={'10px'}> {errorMessage} </Box>:null}
            {
                statusError && statusError.map(item=>
                    <Box className='colorRed' p={'10px'}> {item} </Box>
                )
            }
            <Box>
                <FormControl>
                    <Input type="file" onChange={handleFileChange} padding={"4px"}  />
                </FormControl>
                {/* 
                    <FormControl mt={'15px'} className='d-none'>
                        <RadioGroup onChange={setValue} value={value}>
                            <Stack direction='row'>
                                <Radio value='1'>All</Radio>
                                <Radio value='2'>Errors</Radio>
                                <Radio value='3'>Success</Radio>
                            </Stack>
                        </RadioGroup>
                    </FormControl> 
                */}
                <FormControl>
                    <Button className={Styles.customButton} color={'#ffffff'} mt={'15px'} onClick={handleSubmit}>Upload File</Button>
                </FormControl>
            </Box>
        </>
    )
}

export default Import