import React, { useState, useEffect } from 'react';
import Select from 'react-select'
import PropTypes from 'prop-types';
import {Modal,ModalOverlay,ModalContent,ModalHeader,ModalFooter,ModalBody,ModalCloseButton,useDisclosure,Button,FormControl,FormLabel,Input,Box,Image,useToast,} from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import { SketchPicker } from 'react-color';
import API from '../../../../../../Services/API';
import LoaderSpinner from '../../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Components/Widgets/ErrorWidget/ErrorWidget';

const AddImpactMetric = ({ pillarId, getUpdate, info }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [isLoading, setIsLoading] = useState(false);
    const [impactName, setImpactName] = useState('');
    const [keyFact, setKeyFact] = useState('');
    const [color, setColor] = useState('#ffffff');
    const [file, setFile] = useState(null);
    const [displayColorPicker, setDisplayColorPicker] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [tagDetails, setTagDetails] = useState([]);
    const [selectedTags, setSelectedTags] = useState([]);
    const toast = useToast();
    const token = window.localStorage.getItem('accessToken');
    const [user, setUser] = useState({})
    const handleClose = () => {
        setImpactName('');
        setKeyFact('');
        setColor('#ffffff');
        setFile(null);
        setDisplayColorPicker(false);
        setSuccessMessage(null);
        onClose();
    };
    useEffect(() => {
        if (isOpen) {
            API.get(`/tag-detail/`, {
                headers: { 'Authorization': 'Bearer ' + token },
              })
            .then((response) => {
              setTagDetails(response.data.results)
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }
        API.get(`/get-specific-modules?module_id=249`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setUser(response.data);
        }).catch(error => {
            setIsLoading(false);
        });
    }, [isOpen]);
    const handleColorClick = () => setDisplayColorPicker(!displayColorPicker);
    const handleColorChange = (newColor) => setColor(newColor.hex);
    const handleFileUpload = (e) => setFile(e.target.files[0]);
    const addMetric = async () => {
        setIsLoading(true);
        setSuccessMessage(null);
        const formData = new FormData();
        if (file) formData.append('image', file);
        formData.append('pillar_id', pillarId);
        formData.append('name', impactName);
        formData.append('key_fact', keyFact);
        formData.append('color_picker', color);
        formData.append('metrictype', 'impact');
        formData.append('metric_tag', JSON.stringify(selectedTags.map((tag) => tag.value)))
        if(user.superuser || user.action_office_user){
            formData.append('approval_status', 'approved');
        }else{
            formData.append('approval_status', 'pending');
        }

        try {
            await API.post('/metric', formData, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setSuccessMessage('Successfully created Impact metric');
            getUpdate();
            handleClose();
        }catch (error) {
            const errorMessage = error.response?.data?.name?.[0] || 'An error occurred while creating the metric';
            setSuccessMessage(errorMessage);
        }finally {
            setIsLoading(false);
        }
    };

    const colorOptionLabel = (props) => {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <div
                    style={{
                        width: 20,
                        height: 20,
                        backgroundColor: props.value,
                        marginRight: 10,
                    }}
                />
                {props.value} ({props.label})
            </div>
        );
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {successMessage && <ErrorWidget message={successMessage} />}
            <Button onClick={onOpen} className="defaultButton mrgn">
                <AddIcon mr="10px" /> Add Impact Metric
            </Button>
            <Modal isOpen={isOpen} onClose={handleClose} closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Add New Impact Metric</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <FormControl>
                            <FormLabel fontWeight="bold">Name</FormLabel>
                            <Input
                                type="text"
                                value={impactName}
                                onChange={(e) => setImpactName(e.target.value)}
                                mb="15px"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight="bold">Value</FormLabel>
                            <Input
                                type="text"
                                value={keyFact}
                                onChange={(e) => setKeyFact(e.target.value)}
                                mb="15px"
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight="bold">Background Colour</FormLabel>
                            {/* <Input
                                type="text"
                                value={color}
                                onClick={handleColorClick}
                                readOnly
                                mb="15px"
                            />
                            {displayColorPicker && (
                                <Box position="absolute" zIndex="2">
                                <Box position="fixed" top="0" left="0" right="0" bottom="0" onClick={() => setDisplayColorPicker(false)} />
                                    <SketchPicker color={color} onChange={handleColorChange} />
                                </Box>
                            )} */}
                            <Select
                                options={[
                                    { value: '#E2E2E2', label: 'Light Gray' },
                                    { value: '#CDEBF9', label: 'Sky Blue Tint' },
                                    { value: '#0D274D', label: 'Midnight Blue' },
                                    { value: '#00BCEB', label: 'Sky Blue' },
                                    { value: '#0051AF', label: 'Medium Blue' },
                                    { value: '#E3241B', label: 'Red' },
                                    { value: '#FBAB2C', label: 'Orange' },
                                    { value: '#7FBF4B', label: 'Green' },
                                    { value: '#414344', label: 'Dark Gray' },
                                    { value: '#E2E2E2)', label: 'Light Gray' },
                                ]}
                                onChange={(e) => setColor(e.value)}
                                formatOptionLabel={colorOptionLabel} // Custom rendering
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight={'bold'}>Select Tag</FormLabel>
                            <Select
                                isMulti // Allow multi-selection
                                value={selectedTags}
                                onChange={setSelectedTags} // Handle array of selected tags
                                options={tagDetails.map((tag) => ({
                                    value: tag.id,
                                    label: tag.name,
                                }))}
                                isClearable
                            />
                        </FormControl>
                        <FormControl>
                            <FormLabel fontWeight="bold">Card Image</FormLabel>
                            <Input type="file" onChange={handleFileUpload} padding="4px" />
                            {info?.banner && <Image src={info.banner} alt="Card Image" />}
                        </FormControl>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="ghost" mr={3} onClick={handleClose}>Cancel</Button>
                        <Button colorScheme="blue" onClick={addMetric}>Add</Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

AddImpactMetric.propTypes = {
    pillarId: PropTypes.string.isRequired,
    getUpdate: PropTypes.func.isRequired,
    info: PropTypes.shape({
        banner: PropTypes.string,
    }),
};

export default AddImpactMetric;
