import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import Styles from "../../../../../../Components/ProjectModule/Actions/ViewAction/ActionTabs/Qbr/SlideThree/slideThree.module.css";
import API from "../../../../../../Services/API";
import { useParams } from "react-router-dom";
import Select from "react-select";
import {Box,Table,TableContainer,Tbody,Td,Th,Thead,Tr,Spinner,Text, position, Button,} from "@chakra-ui/react";
import parse from 'html-react-parser';
//import { FiscalYearContext } from '../../Context/FiscalYearContext';

const DataTable = (props) => {
  const token = window.localStorage.getItem("accessToken");
  const { actionid } = useParams();
  const [action, setAction] = useState(null);
  const [progressNextSteps, setProgressNextSteps] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [progressData, setProgressData] = useState([]);
  const [strategies, setStrategies] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  //const { selectedFiscalYearName } = useContext(FiscalYearContext);

  useEffect(()=>{
    getUpdatedRecords()
  }, [token, actionid])

  const getUpdatedRecords = () =>{
    API.get(`/progress-next-steps?action_id=${props.action}`, { 
      headers: { 'Authorization': 'Bearer ' + token } 
    })
    .then(response=>{
      setProgressData(response.data)
    }).catch(error=>{
    }) 
  }

  return (
    <TableContainer
      border="1px"
      mt="20px"
      mb="20px"
      borderColor="rgb(0 160 218)"
      overflowY={'visible'}
    >
      <Table
        variant="simple"
        size="md"
        border="1px"
        borderColor="gray.200"
        aria-label="Progress Next Steps Table"
        overflowY={'visible'}
      >
        <Thead>
          <Tr>
            <Th className={Styles.th}>Action Strategies</Th>
            <Th className={Styles.th}> {} Goals</Th>
            <Th className={Styles.th}>Achievements to Date</Th>
            <Th className={Styles.th}>Next Steps</Th>
          </Tr>
        </Thead>
        <Tbody>
          {progressData && progressData.length > 0 ? (
            progressData.map((row, index) => (
              <Tr key={index}>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.strategy_name}
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.goals != null  ? parse(row.goals) : 'N/A'}
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.achievements_to_date != null  ? parse(row.achievements_to_date) : 'N/A'}
                  </Box>
                </Td>
                <Td className={Styles.th}>
                  <Box maxW="300px" whiteSpace="pre-wrap">
                    {row.next_steps != null  ? parse(row.next_steps) : 'N/A'}
                  </Box>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr>
              <Td colSpan={4} textAlign="center">
                No data available.
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

DataTable.propTypes = {
  progressData: PropTypes.arrayOf(
    PropTypes.shape({
      strategy: PropTypes.string,
      goals: PropTypes.string,
      achievements: PropTypes.arrayOf(PropTypes.string),
      nextSteps: PropTypes.arrayOf(PropTypes.string),
    })
  ),
};

DataTable.defaultProps = {
  progressData: [],
};

export default DataTable;
