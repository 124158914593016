import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const EditContext = createContext();

export const EditProvider = ({ children }) => {
  const location  =  useLocation()
  const [edit, setEditable] = useState(false);
  const [projectModules, setProjectModules] = useState([]);
  const [user, setuserData] = useState(false);
  const [hasDeletePermission, setHasDeletePermission] = useState(false);
  const [hasCommentDeletePermission, setCommentHasDeletePermission] = useState(false);
  const [hasTagDeletePermission, setTagDeletePermission] = useState(false);
  const [hasTagApplyPermission, setTagApplyPermission] = useState(false);
  const [hasAllPermission, setHasAllPermission] =useState(false)
  const [currentHelpTab, setCurrentHelpTab] =useState("FAQs")
  const windowLocation = window.location.pathname;
  const faqPath = windowLocation.split('/').slice(0, 2).join('/');
 
  useEffect(() => {
    setEditable(false);

    // Fetch user data from localStorage
    const response = localStorage.getItem("user");
    const user = response && JSON.parse(response);

    if (user) {
        setuserData(user);
        const hasGlobalPermission = user.action_office_user || user.is_superuser;
        setHasAllPermission(hasGlobalPermission);
      } else {

      }
    }, [location.pathname]);
 
    const setHelpTab = (tab) => {
      setCurrentHelpTab(tab);
    };
    
    const setProjectModuleArray = (module) => {
      const helpModule = module?.filter((a) =>
        ["asio", "faq", "tguides"].some(
          (name) => a.unique_name.includes(name)
        )
      );
      
      setProjectModules(helpModule);
    };
    const setBlogPermission =(permission)=>{
      setHasDeletePermission(permission)
    }
    const setTagPermission =(permission)=>{
      setTagDeletePermission(permission)
    }
    const setCommentPermission =(permission)=>{
      setCommentHasDeletePermission(permission)
    }
    const setTagEditPermission =(permission)=>{
      setTagApplyPermission(permission)
    }

  const setEdit = (iseditable) => {
    setEditable(iseditable);
  };
  
  return (
    <EditContext.Provider value={{edit , setEdit,currentHelpTab,setHelpTab, hasAllPermission ,hasDeletePermission,hasTagApplyPermission, hasCommentDeletePermission, hasTagDeletePermission, user, setBlogPermission, setTagPermission, setCommentPermission ,setTagEditPermission, setProjectModuleArray,projectModules }}>
      {children}
    </EditContext.Provider>
  );
};
