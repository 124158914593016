import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import $ from 'jquery'
import Styles from '../SideBarMenu/sideBarMenu.module.css'
import API from '../../../../../Services/API';
import { Collapse } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import CreateModule from '../CreateModule/CreateModule';
import SideBarMenu from '../SideBarMenu/SideBarMenu';
import {Box, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon,} from '@chakra-ui/react'


const PillarNavigation = () => {
    const token = window.localStorage.getItem("accessToken")
    const user = JSON.parse(window.localStorage.getItem("user"))
    const location = useLocation();
    const { hash, pathname, search } = location;
    const [basicUiMenuOpen, setBasicUiMenuOpen] = useState(false)
    const [pillars, setPillars] = useState(false)
    const [portfolio, setPortfolio] = useState(false)
    const [help, setHelp] = useState(false)
    const [menuList, setMenuList] = useState()

    const openToggle = (event) => {
        if(event.target.title === 'Pillars'){
            setPillars(!pillars)
            setPortfolio(false)
            setHelp(false)
        }else if(event.target.title === 'Portfolio'){
            setPortfolio(!portfolio)
            setPillars(false)
            setHelp(false)
        }else if(event.target.title === 'Help'){
            setHelp(!help)
            setPortfolio(false)
            setPillars(false)
        }
    }

    useEffect(() => {
        API.get(`/project-modules`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        }).then(response=>{

        //     const socialJusticePillar =response?.data && response?.data?.filter(subItem => {
        //         if(subItem?.parent_id===260 || subItem?.id===261|| subItem?.name==="Community Enablement"){
        //         return subItem
        //     }    
            
        // })
            // console.log("menuList",socialJusticePillar)
        
            setMenuList(response && response?.data)
            window.localStorage.setItem('menuList', JSON.stringify(response.data))
            $('.subMenuNew:empty').remove()
            $('.subMenuNew').closest('li').addClass('acordian')
        }).catch(error => {
            if(error.response.data.code === 'token_not_valid'){
                window.localStorage.removeItem('accessToken')
                window.localStorage.removeItem('refreshToken')
                window.localStorage.removeItem('user')
                window.location.href = '/login'
            }
        });
    },[]);

    const updateMenu = (updatedMenu) =>{
        setMenuList(updatedMenu)
    }

    const toogleNew = (event) => {
        $(event.target).toggleClass('newMClass')
        const parentElement = event.target.closest('.nav-pillar-sub');
        $(parentElement).find('ul.subMenuNew').toggleClass('d-none')
    }

    $('.subMenuNew:empty').remove()
    $('.subMenuNew').closest('li').addClass('acordian')
    
    const customSort = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
      
        // Extract numbers from the names
        const numA = parseInt(nameA.match(/\d+/), 10);
        const numB = parseInt(nameB.match(/\d+/), 10);
      
        // Compare numbers if both names have numbers
        if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
            return numA - numB;
        }
      
        // Compare strings
        return nameA.localeCompare(nameB);
    };

    $(document).ready(function(){
        var idCounts = {};
        $('li.nav-item-main-pillar').each(function() {
            var elementId = $(this).attr('id');
    
            // Increment the count for this ID
            if (idCounts[elementId]) {
                idCounts[elementId]++;
            } else {
                idCounts[elementId] = 1;
            }
    
            // Hide the element if it's a duplicate
            if (idCounts[elementId] > 1) {
                $(this).hide();
            }
        });
    })


    return (
        <>
            <Accordion allowToggle border={'none'}>
                {menuList && menuList.map((item) => {
                    const subItems = menuList.filter(subItem => subItem.parent_id === item.id).sort(customSort);
                    return item.category === 'pillar' && item.parent_id === null ? (
                        subItems.length > 0 ? (
                            <AccordionItem key={item.id} bg="transparent" border={'none'}>
                                <AccordionButton className={Styles.acrdnButton} pb={'0'}>
                                    <Box as='span' flex='1' textAlign='left' className={Styles.span}>
                                        <Link className={'overwrite nav-link'} to={`/${item.category}/${item.id}/${encodeURIComponent(item.name)}`}>
                                            {item.name}
                                        </Link>
                                    </Box>
                                    {/* Accordion Icon positioned to the right of the text */}
                                    <AccordionIcon color={"#00aae0"} />
                                </AccordionButton>
                                <AccordionPanel pb={"0"} bg="transparent" pt={'0'}>
                                    <ul className='subMenuNew'>
                                        {subItems.map(subItem => (
                                            <li key={subItem.id} className={` ${Styles.mnLi}`}>
                                                <Link className={`nav-link ${Styles.subNavLink}`} pl={'0'} to={`/${item.category}/${item.id}/${encodeURIComponent(item.name)}/${subItem.id}/${encodeURIComponent(subItem.name)}`}>
                                                    {subItem.name}
                                                </Link>
                                                <Box className='clearfix'></Box>
                                            </li>
                                        ))}
                                    </ul>
                                </AccordionPanel>
                            </AccordionItem>
                        ) : (
                            <Box as="li" key={item.id} className="nav-item">
                                <Link className={`nav-link ${Styles.mnLink}`} to={`/${item.category}/${item.id}/${encodeURIComponent(item.name)}`}>
                                    {item.name}
                                </Link>
                            </Box>
                        )
                    ) : null;
                })}
            </Accordion>

        </>
    )
}

export default PillarNavigation