import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from '../viewpost.module.css'
import $ from 'jquery'
import API from '../../../../../Services/API';
import Select from 'react-select';
import { Heading, Box, Image, Text, Input, Textarea, Divider, Button, Card, CardHeader, CardBody, CardFooter, Stack, ButtonGroup, Grid, GridItem, StackDivider  } from '@chakra-ui/react'
import { Tag, TagLabel, TagLeftIcon, TagRightIcon, TagCloseButton,} from '@chakra-ui/react'
import {FormControl, FormLabel, FormErrorMessage, FormHelperText, Badge } from '@chakra-ui/react'
import LoaderSpinner from '../../../../../Components/Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../Components/Widgets/ErrorWidget/ErrorWidget';
import { EditContext } from '../../../../../Context/BreadcrumbsContext';

const Pillars = (props) => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(false)
    const [pillar, setPillar] = useState()
    const [selectedOptions, setSelectedOptions] = useState()
    const [message, setMessage] = useState(null)
    
    const {hasTagApplyPermission ,user} = useContext(EditContext);


    const customSort = (a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
      
        // Extract numbers from the names
        const numA = parseInt(nameA.match(/\d+/), 10);
        const numB = parseInt(nameB.match(/\d+/), 10);
      
        // Compare numbers if both names have numbers
        if (!isNaN(numA) && !isNaN(numB) && numA !== numB) {
            return numA - numB;
        }
      
        // Compare strings
        return nameA.localeCompare(nameB);
    };

    useEffect(() => {
        document.documentElement.scrollTo(0, 0);
        API.get(`/all-pillar`, {
            headers: {
              'Authorization': 'Bearer ' + token
            }
        })
        .then((response) => {
            const sortedData = response && response?.data?.sort(customSort);
            setPillar(sortedData && sortedData)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[])
    
    const options = pillar && pillar.map(item =>
        ({label: item.name, value: item.id})
    )

    const defaultSelectedOptions = props.selectedOptions && props.selectedOptions.map(item =>
        ({label: item.name, value: item.id})
    )

    const selectOption = (event) =>{
        setSelectedOptions(event.map(option => option.value));
        const current_Pillar_ID= event[event?.length-1]?.value;
        const current_Pillar_IDS=Array.isArray(event) && event.map(option => option.value);
        props.setBlogPillarsID(current_Pillar_ID)
        props?.setBlogPillarsIdsforActions(Array.isArray(current_Pillar_IDS) && current_Pillar_IDS)
    }

    const addOptions = () => {
        setMessage(null); // Clear any previous message
        setIsLoading(true); // Set loading to true
    
        try {
            const formData = new FormData();
            formData.append('blog_id', parseInt(props.id));
            formData.append('blog_pillar', JSON.stringify(selectedOptions));
            formData.append(
                "approval_status",
                (Array.isArray(selectedOptions) && selectedOptions?.length===0) ? "approved" : "pending"
              );
            // formData.append('approval_status',  "pending");
    
            // API request to update the blog
            API.put(`/blog`, formData, {
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
            .then((response) => {
                // After updating, get the latest blog data
                API.get(`/blog?id=${props.id}`, {
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                })
                .then((response) => {
                    setMessage('Changes updated successfully');
                    props.getUpdated(response.data[0]); // Pass updated data to parent component
                    setIsLoading(false); // Set loading to false
                })
                .catch((error) => {
                    setIsLoading(false);
                });
            })
            .catch((error) => {
                setMessage(error &&  error.response &&  error.response.data && error.response.data.msg);
                setIsLoading(false);
            });
        } catch (error) {
            setIsLoading(false);
        }
    };
    

    return (
        <>
            { 
                isLoading ? <LoaderSpinner />: null
            }
            {message && message ? <ErrorWidget message={message && message} /> : null}
            <Box>
                <Heading className={Styles.sideBarHeading}>
                Social Impact & Inclusion Office
                </Heading>
                <Text p='0' className={Styles.sideBarText}>
                    {
                        props.editable ?
                            <>
                                <Select
                                    placeholder="Select Social Impact & Inclusion Office"
                                    aria-label="Select Social Impact & Inclusion Office"
                                    options={options}
                                    isMulti
                                    defaultValue={defaultSelectedOptions}
                                    onChange={selectOption}
                                />
                                <Button  background={'#00aae0'} color={'#ffffff'} mt={'5px'} onClick={addOptions}> Save </Button>
                            </>
                        : <>
                            {
                                props.selectedOptions && props.selectedOptions.map(item => 
                                    <Tag
                                        borderRadius='full'
                                        variant='solid'
                                        background={'#d4d4d8'}
                                        color={"#000000"}
                                        mr={'5px'}
                                        mb={'5px'}
                                    >
                                        <TagLabel>{item.name}</TagLabel>
                                    </Tag>
                                )
                            }
                    </>
                    }
                    
                </Text>
            </Box>
        </>
    )
}

export default Pillars