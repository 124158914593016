import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import Styles from './mytasks.module.css'
import $ from 'jquery'
import API from '../../Services/API';
import { Box, Button, Card, CardBody, Text } from '@chakra-ui/react'
import BreadCrumbs from '../../Components/Widgets/BreadCrumbs/BreadCrumbs';
import MetricData from './MetricData/MetricData';
import ImpactGalleryItems from './ImpactGalleryItems/ImpactGalleryItems';
import ElrPending from './ElrPending/ElrPending'


import Elr from './Elr/Elr';
import ElrTaskHistory from './ElrTaskHistory/ElrTaskHistory';

import BreadCrumbsNav from "../../Components/Shared/Navbar/BreadCrumbsNav";
import { EditContext } from "../../Context/BreadcrumbsContext";

import Metrics from './Metrics/Metrics'
import Aspirations from './Aspirations/Aspirations';
import Notifications from './Notifications/Notifications';
import Qbr from './Qbr/Qbr';

const MyTasks = () => {
    const [editable, setEditable] = useState();
    const [notificationCount, setNotificationCount] = useState(0);
    const geteditStatus = (isEdit) =>{
      setEditable(isEdit)
    }
    const [activeTab, setActiveTab] = useState('impactGalleryItems');
    const { edit, setEdit, hasAllPermission } = useContext(EditContext);
    const renderTabContent = () => {
        switch (activeTab) {
            case 'notifications': return <Notifications getNotificationCount={getNotificationCount} />
            case 'metrics': return <Metrics />;
            case 'metricDataPending': return <MetricData />;
            case 'aspirations': return <Aspirations />;
            case 'impactGalleryItems': return <ImpactGalleryItems />;
            case 'elrpending': return <ElrPending />;
            case 'elrtaskhistory': return <ElrTaskHistory />;
            case 'qbr': return <Qbr />;
            default : return null;
        }
    };

    const getNotificationCount = (count) => {
        setNotificationCount(count)
    }

    return (
        <>
            <BreadCrumbs geteditStatus={geteditStatus} title={'My Tasks'} />
            <Box>
                <Card>
                    <CardBody>
                        <Box>
                        {/* {hasAllPermission && */}
                        <>
                            <Button onClick={() => setActiveTab('notifications')} className={activeTab === 'notifications' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Notifications <Text as={'span'} ml={'5px'} className={Styles.circleNew}> {notificationCount} </Text> </Button>
                            <Button onClick={() => setActiveTab('metrics')} className={activeTab === 'metrics' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Metrics</Button>
                            <Button onClick={() => setActiveTab('metricDataPending')} className={activeTab === 'metricDataPending' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Metric Data</Button>
                            <Button onClick={() => setActiveTab('aspirations')} className={activeTab === 'aspirations' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Aspirations</Button>
                            <Button onClick={() => setActiveTab('impactGalleryItems')} className={activeTab === 'impactGalleryItems' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>Impact Gallery Items</Button>
                            <Button onClick={() => setActiveTab('qbr')} className={activeTab === 'qbr' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>SIIO Quarterly Business Review</Button>
                            <Button onClick={() => setActiveTab('elrpending')} className={activeTab === 'elrpending' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>ELR</Button>
                            <Button onClick={() => setActiveTab('elrtaskhistory')} className={activeTab === 'elrtaskhistory' ? `${Styles.active} ${Styles.tabStyle}` : `${Styles.tabStyle}`}>ELR Task History</Button>
                         </>   
                        {/* } */}
                        </Box>
                        <Box className={Styles.tabContent}>
                            {renderTabContent()}
                        </Box>
                    </CardBody>
                </Card>
            </Box>

            
        </>
    )
}

export default MyTasks