import React, { useState, useEffect, useRef, useMemo } from 'react';
import { DownloadIcon, AddIcon, WarningIcon } from '@chakra-ui/icons'
import jsPDF from 'jspdf';
import pptxgen from 'pptxgenjs';
import html2canvas from 'html2canvas';
import {Box, Button } from '@chakra-ui/react';

const GeneratePdf = () => {
    const printPDF = async () => {
        try {
            const bgColor = '#1A365D'; // Background color for all pages
            const customPageWidth = 297; // A4 landscape width in mm
            const customPageHeight = 210; // A4 landscape height in mm
    
            const pdf = new jsPDF('l', 'mm', [customPageWidth, customPageHeight]);
            const pageWidth = pdf.internal.pageSize.getWidth();
            const pageHeight = pdf.internal.pageSize.getHeight();
    
            const sectionIds = ['slideOne', 'slideTwo', 'slideThree', 'slideFour'];
            const footerHeight = 10; // Footer height in mm
    
            const scaleToFit = (imgWidth, imgHeight, maxWidth, maxHeight) => {
                const widthScaleFactor = maxWidth / imgWidth;
                let finalWidth = imgWidth * widthScaleFactor;
                let finalHeight = imgHeight * widthScaleFactor;
    
                if (finalHeight > maxHeight) {
                    const heightScaleFactor = maxHeight / imgHeight;
                    finalWidth = imgWidth * heightScaleFactor;
                    finalHeight = imgHeight * heightScaleFactor;
                }
                return { finalWidth, finalHeight };
            };
    
            for (let i = 0; i < sectionIds.length; i++) {
                const element = document.getElementById(sectionIds[i]);
                if (element) {
                    // Add a new page for each section after the first
                    if (i > 0) pdf.addPage();
    
                    // Draw the background color for the entire page
                    pdf.setFillColor(bgColor);
                    pdf.rect(0, 0, pageWidth, pageHeight, 'F');
    
                    // Wait for images within the section to load
                    const images = element.querySelectorAll('img');
                    const loadPromises = Array.from(images).map((img) =>
                        new Promise((resolve) => {
                            if (img.complete) {
                                resolve();
                            } else {
                                img.onload = resolve;
                                img.onerror = resolve;
                            }
                        })
                    );
    
                    await Promise.all(loadPromises);
    
                    const canvas = await html2canvas(element, {
                        scale: 2,
                        useCORS: true,
                        backgroundColor: null, // Ensure transparent background for the canvas
                        ignoreElements: (el) => el.id === 'excludeId', // Skip specific elements if needed
                    });
    
                    const imgData = canvas.toDataURL('image/png');
    
                    const { finalWidth, finalHeight } = scaleToFit(
                        canvas.width,
                        canvas.height,
                        pageWidth,
                        pageHeight - footerHeight
                    );
    
                    const xOffset = (pageWidth - finalWidth) / 2;
                    const yOffset = (pageHeight - footerHeight - finalHeight) / 2;
    
                    // Add the canvas image to the PDF
                    pdf.addImage(imgData, 'PNG', xOffset, yOffset, finalWidth, finalHeight);
                } else {
                    console.error(`Element with ID ${sectionIds[i]} not found.`);
                }
            }
    
            // Add the footer element separately
            const footerElement = document.getElementById('footerPdf');
            if (footerElement) {
                try {
                    const footerCanvas = await html2canvas(footerElement, {
                        scale: 2,
                        useCORS: true,
                        backgroundColor: null, // Makes the background transparent
                    });
                    const footerImgData = footerCanvas.toDataURL('image/png');
    
                    // Set width for the footer image
                    const footerWidth = pageWidth * 0.5; // 50% of page width
                    const footerHeight = (footerCanvas.height / footerCanvas.width) * footerWidth;
    
                    // Position the footer on the left side at the bottom with a small margin
                    const xOffset = 10; // Left margin (10 mm from the left)
                    const footerYOffset = pageHeight - footerHeight - 10; // Bottom Y-offset with 10 mm margin
    
                    // Add footer to the bottom left of the first page only
                    pdf.setPage(1); // Ensure footer is added to the first page
                    pdf.addImage(
                        footerImgData,
                        'PNG',
                        xOffset,          // Left alignment
                        footerYOffset,    // Bottom Y-offset
                        footerWidth,
                        footerHeight
                    );
                } catch (error) {
                    console.error("Error capturing or adding footer:", error);
                }
            } else {
                console.error("Footer element with ID 'footerPdf' not found.");
            }
    
            pdf.save('document.pdf');
        } catch (error) {
            console.error('Error generating PDF:', error);
        }
    };
    
    
    

    const downloadPPT = async () => {
        try {
            const pptx = new pptxgen();
            const elementIds = ['slideOne', 'slideTwo', 'slideThree', 'slideFour'];
            const excludeId = 'excludeId'; // Define the ID you want to exclude
    
            // Define slide dimensions (width and height in inches)
            const slideWidthInches = 10; // Slide width in inches
            const slideHeightInches = 5.63; // Slide height in inches
    
            const footerElement = document.getElementById('footerPdf');
            let footerText = '';
            let footerImageData = null;
    
            if (footerElement) {
                // Get footer text
                footerText = footerElement.innerText || '';
    
                // Check for an image inside footerPdf
                const footerImage = footerElement.querySelector('img');
                if (footerImage) {
                    const response = await fetch(footerImage.src);
                    const blob = await response.blob();
                    footerImageData = await new Promise((resolve) => {
                        const reader = new FileReader();
                        reader.onloadend = () => resolve(reader.result);
                        reader.readAsDataURL(blob);
                    });
                }
            }
    
            // Function to add footer to a slide
            const addFooterToSlide = (slide) => {
                if (footerImageData) {
                    const footerImageX = 0.5; // Adjust as needed for your layout
                    const footerImageY = slideHeightInches - 0.8; // Footer image position
    
                    // Add the footer image
                    slide.addImage({
                        data: footerImageData,
                        x: footerImageX,
                        y: footerImageY,
                        w: 0.625,
                        h: 0.329
                    });
    
                    // Calculate the position for the footer text
                    const footerTextX = footerImageX + 0.7; // Position to the right of the image
                    const footerTextY = footerImageY + 0.05; // Align with the image vertically
    
                    // Add footer text next to the image
                    slide.addText(footerText, {
                        x: footerTextX,
                        y: footerTextY,
                        w: slideWidthInches - footerTextX - 0.5, // Width of the text box
                        h: 0.3, // Height of the text box
                        fontSize: 9,
                        color: "FFFFFF", // Text color
                        align: pptx.AlignH.left // Left-align the footer text
                    });
                }
            };
    
            for (const id of elementIds) {
                const element = document.getElementById(id);
    
                if (element) {
                    try {
                        // Capture the element as a canvas with ignoreElements option
                        const canvas = await html2canvas(element, {
                            scale: 2, // Increase resolution of the canvas
                            useCORS: true,
                            backgroundColor: '#1A365D', // Ensure the background color is captured
                            ignoreElements: (el) => el.id === excludeId // Exclude element by ID
                        });
    
                        const imgData = canvas.toDataURL('image/png');
                        const slide = pptx.addSlide();
    
                        // Add a consistent background color for all slides
                        slide.addShape(pptx.ShapeType.rect, {
                            x: 0,
                            y: 0,
                            w: slideWidthInches,
                            h: slideHeightInches,
                            fill: { color: '#1A365D' } // Background color
                        });
    
                        // Get the canvas aspect ratio
                        const canvasWidth = canvas.width;
                        const canvasHeight = canvas.height;
                        const aspectRatio = canvasWidth / canvasHeight;
    
                        // Set image dimensions while maintaining aspect ratio
                        let imgWidth = slideWidthInches;
                        let imgHeight = imgWidth / aspectRatio;
    
                        // Adjust dimensions if image height exceeds slide height
                        if (imgHeight > slideHeightInches) {
                            imgHeight = slideHeightInches;
                            imgWidth = imgHeight * aspectRatio;
                        }
    
                        // Calculate positions to center the image
                        const xPos = (slideWidthInches - imgWidth) / 2;
                        const yPos = (slideHeightInches - imgHeight) / 2;
    
                        // Add the image to the slide
                        slide.addImage({
                            data: imgData,
                            x: xPos,
                            y: yPos,
                            w: imgWidth,
                            h: imgHeight
                        });
    
                        // Add footer to the slide
                        addFooterToSlide(slide);
    
                    } catch (captureError) {
                        console.error(`Error capturing element with id "${id}":`, captureError);
                    }
                } else {
                    console.warn(`Element with id "${id}" not found.`);
                }
            }
    
            // Save the presentation file
            await pptx.writeFile('presentation.pptx');
    
        } catch (error) {
            console.error('Error creating presentation:', error);
        }
    };
    
    

    return (
        <>
            <Box float={'right'}>
                <Button mt="0"  mr={3} colorScheme="blue" onClick={printPDF} float={'left'} mb={'10px'}>
                    Download as PDF
                </Button>
                <Button mt="0"  mr={3} colorScheme="blue" onClick={downloadPPT} float={'right'} mb={'10px'}>
                    Download as PPT
                </Button>
            </Box>
            
        </>
    )
}

export default GeneratePdf