import React, { useState } from "react";
import API from "../../../../../../../../../Services/API";
import { useParams } from "react-router-dom";
import { IconButton, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, ModalCloseButton, Button, Text } from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import Styles from '../EditRecord/editRecord.module.css';
import LoaderSpinner from "../../../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner";
import ErrorWidget from "../../../../../../../../Widgets/ErrorWidget/ErrorWidget";

const DeleteRecord = (props) => {
    const token = window.localStorage.getItem("accessToken");
    const { actionid } = useParams();
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false)
    const [message, setMessage] = useState(null)

    const saveRecord = async () => {
        setIsLoading(true)
        setMessage(null)
        try {
            const response = await API.delete(`/progress-next-steps`, {
                data: { id: props.id },
                headers: { Authorization: `Bearer ${token}` }
            });
            setMessage("Record deleted successfully!");
            setIsOpen(false);
            setIsLoading(false)
        } catch (error) {
            setMessage("An error occurred while deleting the record.");
            setIsOpen(false);
            setIsLoading(false)
        }
    };

    return (
        <>
            {isLoading && <LoaderSpinner /> }
            {message && <ErrorWidget message={message} /> }
            <IconButton
                minW={'0'}
                minH={'0'}
                height={'25px'}
                w={'25px'}
                variant="solid"
                aria-label="Delete"
                icon={<DeleteIcon />}
                className={Styles.edit}
                onClick={() => setIsOpen(true)}
                ml={'5px'}
            />
            <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Delete</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text>Are you sure you want to delete this record? This action cannot be undone.</Text>
                    </ModalBody>
                    <ModalFooter backgroundColor={'transparent'}>
                        <Button backgroundColor={'transparent'} onClick={() => setIsOpen(false)}>Cancel</Button>
                        <Button onClick={saveRecord} colorScheme="blue" ml={3}>
                            Delete
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default DeleteRecord;
