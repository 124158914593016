import React, { useEffect, useState, useContext } from 'react';
import Styles from './slideThree.module.css';
import API from '../../../../../../../Services/API';
import { EditContext } from '../../../../../../../Context/BreadcrumbsContext';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, Image, SimpleGrid, Text, Textarea, Img } from '@chakra-ui/react';
import FooterImg from '../../../../../../../../assets/images/cisco-white.png';
import SupportImg from './support.png';
import LoaderSpinner from '../../../../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../../../../Widgets/ErrorWidget/ErrorWidget';


const SlideFour = (props) => {
    const { actionid } = useParams();
    const { edit } = useContext(EditContext);
    const token = window.localStorage.getItem("accessToken");
    const [isLoading, setIsloading] = useState(false);
    const [message, setMessage] = useState(false);
    const [action, setAction] = useState(null);
    const [changeMessage, setChangeMessage] = useState(null);
    const [supportNeededData, setSupportNeededData] = useState({});
    const [supportNeededDataLength, setSupportNeededDataLength] = useState(0);
    const [selectedId, setSelectedId] = useState(null);
    const [status, setStatus] = useState(null);
    const [user, setUser] = useState({})

    useEffect(() => {
        API.get(`/get-specific-modules?module_id=${actionid}`, {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        }).then((response) => {
            setUser(response.data);
        }).catch(error => {
        });

        API.get(`/pillar?module_id=${actionid}`, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
            .then((response) => {
                setAction(response.data);
                API.get(`/support-needed?action_id=${response.data.id}`, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                .then((response) => {
                    setSupportNeededData(response.data[0]);
                    setSupportNeededDataLength(response.data.length);
                    setSelectedId(response.data[0]?.id);
                    setStatus(response.data[0]?.approval_status);
                    props.getSlide4Status(response.data[0]?.approval_status);
                })
                .catch((error) => {});
            })
            .catch((error) => {});
    }, [actionid, token, props]);

    const handleChange = (event) => {
        setChangeMessage(event.target.value);
    };

    const handleSubmit = () => {
        setIsloading(true);
        setMessage(null);
        

        const currentMessage = supportNeededData?.msg || ""; // Handle cases where msg might be null/undefined

        if (!changeMessage || changeMessage.trim() === (supportNeededData?.msg || '').trim()) {
            setMessage("No changes are made");
            setIsloading(false);
        }else{
            if (supportNeededDataLength > 0) {
                const body = {
                    approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Ready to Submit",
                    msg: changeMessage,
                    id: selectedId,
                    module_id: Number(actionid),
                };
    
                API.put(`/support-needed`, body, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                    .then((res) => {
                        setIsloading(false);
                        setMessage('Changes updated successfully');
                        props.getSlide4Status("Ready to Submit");
                        API.get(`/support-needed?action_id=${action.id}`, {
                            headers: {
                                Authorization: 'Bearer ' + token,
                            },
                        })
                        .then((response) => {
                            setSupportNeededData(response.data[0]);
                            setSupportNeededDataLength(response.data.length);
                            setSelectedId(response.data[0]?.id);
                            setStatus(response.data[0]?.approval_status);
                            props.getSlide4Status(response.data[0]?.approval_status);
                        })
                        .catch((error) => {
    
                        });
                    })
                    .catch((error) => {
                        setIsloading(false);
                        setMessage(
                            error.response.data.non_field_errors
                                ? error.response.data.non_field_errors[0]
                                : error.response.data.msg || 'Facing technical error'
                        );
                    });
            } else {
                const body = {
                    approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Ready to Submit",
                    msg: changeMessage,
                    visible: true,
                    selectedfy: props.selectedFiscalYearID,
                    action: action.id,
                    module_id: Number(actionid),
                };
                API.post(`/support-needed`, body, {
                    headers: {
                        Authorization: 'Bearer ' + token,
                    },
                })
                    .then((res) => {
                        setIsloading(false);
                        setMessage('Changes updated successfully');
                        props.getSlide4Status("Ready to Submit");
                        API.get(`/support-needed?action_id=${action.id}`, {
                            headers: {
                                Authorization: 'Bearer ' + token,
                            },
                        })
                        .then((response) => {
                            setSupportNeededData(response.data[0]);
                            setSupportNeededDataLength(response.data.length);
                            setSelectedId(response.data[0]?.id);
                            setStatus(response.data[0]?.approval_status);
                            props.getSlide4Status(response.data[0]?.approval_status);
                        })
                        .catch((error) => {
    
                        });
                    })
                    .catch((error) => {
                        setIsloading(false);
                        setMessage(
                            error.response.data.non_field_errors
                                ? error.response.data.non_field_errors[0]
                                : error.response.data.msg || 'Facing technical error'
                        );
                    });
            }
        }

        
    };

    const submitForApproval = () => {
        setIsloading(true);
        setMessage(null);
        const body = {
            approval_status: (user.superuser || user.action_office_user || user.position === "action_owner") ? "Approved" : "Submitted",
            id: selectedId,
            module_id: Number(actionid),
        };

        API.put(`/support-needed`, body, {
            headers: {
                Authorization: 'Bearer ' + token,
            },
        })
            .then((res) => {
                setIsloading(false);
                setStatus("Submitted");
                setMessage('QBR has been submitted for approval.');
                props.getSlide4Status("Submitted");
            })
            .catch((error) => {
                setIsloading(false);
                setMessage(
                    error.response.data.non_field_errors
                        ? error.response.data.non_field_errors[0]
                        : error.response.data.msg || 'Facing technical error'
                );
            });
    };

    return (
        <>
            {isLoading && <LoaderSpinner />}
            {message && <ErrorWidget message={message} />}
            <Box id="slideFour">
                <Box className={Styles.container} mt={'10px'}>
                    <Box width={'100%'} maxWidth={'1080px'} m={'0 auto'}>
                        <Box className={Styles.subContainer} minH={'360px'} position={'relative'}>
                            <Box className={Styles.centerBox}>
                                <Box m={'30px 0'}>
                                    <SimpleGrid columns={2} spacing={10}>
                                        <Box>
                                            <Text textAlign={'center'}>
                                                <Text as={'span'} fontSize={'32px'}>
                                                    Support Needed?
                                                </Text>
                                                <Image src={SupportImg} maxW={'200px'} m={'0 auto'} />
                                            </Text>
                                        </Box>
                                        <Box position={'relative'}>
                                            <Text className={Styles.vCenter} textAlign={'left'}>
                                                {edit ? (
                                                    <Box p={0} maxW="md" mx="auto">
                                                        <FormControl mb={4}>
                                                            <Textarea
                                                                placeholder="Please type something..."
                                                                onChange={handleChange}
                                                                borderColor={'#63b3ed'}
                                                                defaultValue={supportNeededData?.msg || ''}
                                                                className={Styles.Input}
                                                                height={'380px'}
                                                            />
                                                        </FormControl>
                                                        <Button
                                                            borderColor={'#63b3ed'}
                                                            color={'#ffffff'}
                                                            variant="outline"
                                                            _hover={{
                                                                backgroundColor: '#63b3ed',
                                                                color: '#ffffff',
                                                            }}
                                                            onClick={handleSubmit}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                ) : (
                                                    <Text as={'span'} fontSize={'18px'}>
                                                        {supportNeededData?.msg &&
                                                            supportNeededData.msg.charAt(0).toUpperCase() +
                                                                supportNeededData.msg.slice(1)}
                                                    </Text>
                                                )}
                                            </Text>
                                        </Box>
                                    </SimpleGrid>
                                </Box>
                            </Box>
                        </Box>
                        <Box float={'right'} mt={'-12px'} position={'relative'} zIndex={'9'}>
                            {status === "Ready to Submit" && (
                                <Button onClick={submitForApproval}>Submit for Approval</Button>
                            )}
                        </Box>
                        <Text fontSize="sm" position="relative" top="5px" left="0px" fontWeight="light" color="#ffffff">
                            <Img src={FooterImg} maxW={'40px'} display={'inline'} mr={'10px'} />
                            <Text fontSize={'11px'} as={'span'}>
                                © 2024 Cisco and/or its affiliates. All rights reserved. Cisco confidential.
                            </Text>
                        </Text>
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default SlideFour;
