import { createContext, useState } from 'react';

export const FiscalYearContext = createContext();

export const FiscalYearProvider = ({ children }) => {
    const [selectedFiscalYearName, setSelectedFiscalYearName] = useState(null);

    return (
        <FiscalYearContext.Provider value={{ selectedFiscalYearName, setSelectedFiscalYearName }}>
            {children}
        </FiscalYearContext.Provider>
    );
};