import React, { useState, useEffect, useRef, useMemo, useContext } from 'react';
import $ from 'jquery'
import API from '../../../../Services/API';
import { useParams } from 'react-router-dom'
import {
    Card, 
    CardHeader, 
    CardBody, 
    Heading, 
    Tabs, 
    TabList, 
    TabPanels, 
    Tab, 
    TabPanel, 
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Text, Box, Input, Textarea, Button, Image, Grid, GridItem, SimpleGrid, Switch,
    Select, Stack, useToast, Link
} from '@chakra-ui/react'
import Breadcrumb from '../../../../Components/Widgets/BreadCrumbs/BreadCrumbs'
import JoditEditor from 'jodit-react';
import parse from 'html-react-parser';
import LoaderSpinner from '../../../Widgets/CommonWidgets/LoaderSpinner/LoaderSpinner';
import ErrorWidget from '../../../Widgets/ErrorWidget/ErrorWidget'
import Face10 from '../../../../../assets/images/defaultAvatar.png'
import PlaceholderImage from '../../../../../assets/images/userPlaceholder.svg'
import { EditContext } from '../../../../Context/BreadcrumbsContext';

const MemberDetails = () => {
    const token = window.localStorage.getItem("accessToken")
    const [isLoading, setIsLoading]  = useState(true)
    const [editable, setEditable] = useState();
    const editor = useRef(null);
    const [file, setFile] = useState(null);
    const [memberInfo, setMemberInfo] = useState()
    const {memberId} = useParams();
    const [actionOfficeUser, setActionOfficeUser] = useState(false)
    const {edit, setEdit } = useContext(EditContext);
    const [errors, setErrors] = useState({})
    const [userId, setUserId] = useState({})
    const [message, setMessage] = useState(null)


    useEffect(()=>{
        document.documentElement.scrollTo(0, 0);
        setIsLoading(true)
        API.get(`/team-member?member_id=${memberId}`)
        .then((response) => {
            setMemberInfo(response.data[0])
            setUserId(response.data[0].user)
            setIsLoading(false)
        }).catch(error=>{
            setIsLoading(false)
        })
    },[memberId])

    const updateProfile = () => {
        setIsLoading(true);
        setMessage(null);
    
        // Collect the current form data
        const newMemberData = {
            description: $("#description").val(),
            position: $("#position").val(),
            priority: $("#priority").val(),
            action_office_user: actionOfficeUser,
            first_name: $("#first_name").val(),
            last_name: $("#last_name").val(),
            webex_url: $("#webex_url").val(),
            job_title: $("#job_title").val(),
        };
    
        // Check if any data has changed
        const hasChanges = Object.keys(newMemberData).some((key) => {
            return newMemberData[key]?.toString() !== (memberInfo[key]?.toString() || "");
        }) || file; // Check if a new file was uploaded
    
        if (!hasChanges) {
            setMessage("No changes were made");
            setIsLoading(false);
            return;
        }
    
        // Proceed with API calls if changes are detected
        const pillarTeamFormData = new FormData();
        pillarTeamFormData.append("member_id", memberId);
        pillarTeamFormData.append("description", newMemberData.description);
        pillarTeamFormData.append("position", newMemberData.position);
        pillarTeamFormData.append("priority", newMemberData.priority);
        pillarTeamFormData.append("action_office_user", newMemberData.action_office_user);
    
        const userManagementFormData = new FormData();
        if (file) {
            userManagementFormData.append("profile_picture", file);
        }
        userManagementFormData.append("user_id", userId);
        userManagementFormData.append("first_name", newMemberData.first_name);
        userManagementFormData.append("last_name", newMemberData.last_name);
        userManagementFormData.append("webex_url", newMemberData.webex_url);
        userManagementFormData.append("job_title", newMemberData.job_title);
    
        // Update /team-member endpoint
        API.put(`/team-member`, pillarTeamFormData, {
            headers: {
                Authorization: "Bearer " + token,
            },
        })
        .then(() => {
            // Update user-management endpoint
            return API.put(`/user-management`, userManagementFormData, {
                headers: {
                    Authorization: "Bearer " + token,
                },
            });
        })
        .then(() => {
            // Fetch updated profile information after both updates
            return API.get(`/team-member?member_id=${memberId}`);
        })
        .then((response) => {
            setMemberInfo(response.data[0]);
            setIsLoading(false);
            setMessage("Updated profile successfully");
        })
        .catch((error) => {
            setErrors(error.response?.data);
            setMessage(error.response?.data || "An error occurred");
            setIsLoading(false);
        });
    };
    
    const geteditStatus = (isEdit) =>{
        setEditable(isEdit)
    }
    
    function bannerUpload(e) {
        setFile(e.target.files[0]);
    }

    const onHandleChange = (event) =>{
        
        setActionOfficeUser(event.target.checked)
    }

    const role = [
        {
            "value": "owner",
            "label": "Owner",
        },
        {
            "value": "pm",
            "label": "PM",
        },
        {
            "value": "member",
            "label": "Member",
        },
        {
            "value": "lead",
            "label": "Lead",
        },
        {
            "value": "advisor",
            "label": "Advisor",
        },
        {
            "value": "vp",
            "label": "VP",
        },
        {
            "value": "comms",
            "label": "Comms",
        },
        {
            "value": "action_leader",
            "label": "Action Leader",
        },
        {
            "value": "sio_leader",
            "label": "SIO Leader",
        },
        {
            "value": "sio_key_contact",
            "label": "SIO Key Contact",
        },
        {
            "value": "senior_executive_administrator",
            "label": "Sr. Executive Administrator",
        },
        {
            "value": "director_corporate_affairs",
            "label": "Director, Corporate Affairs",
        },
        {
            "value": "director_social_impact_office",
            "label": "Director, Social Impact Office",
        },
        {
            "value": "director_social_justice_action_office",
            "label": "Director, Social Justice Action Office",
        },
        {
            "value": "director_community_impact",
            "label": "Director, Community Impact",
        },
        {
            "value": "svp_chief_impact_officer",
            "label": "SVP, Chief Impact Officer",
        },
    ]

    const position = [
        {
            "value": "action_contributor",
            "label": "Action Contributor",
        },
        {
            "value": "action_owner",
            "label": "Action Owner",
        },
        {
            "value": "action_team_member",
            "label": "Action Team Member",
        },
        {
            "value": "pillar_contributor",
            "label": "Pillar Contributor",
        },
        {
            "value": "pillar_owner",
            "label": "Pillar Owner",
        },
        {
            "value": "pillar_team_member",
            "label": "Pillar Team Member",
        },
    ];

    return (
        <>
            { isLoading ? <LoaderSpinner />: null}
            {message ? <ErrorWidget message={message} /> : null}
            <Breadcrumb geteditStatus={geteditStatus} title={memberInfo && memberInfo.member_name} pillarId={''} />
            {Object.entries(errors).map(([key, value]) => (
                <Box className='colorRed' key={key} mb={'15px'}>
                    <Text as={'span'} textTransform={'capitalize'}> {key} </Text> : <Text as={'span'}> {value} </Text>
                </Box>
            ))}
            <Card>
                <CardBody>
                    <Grid
                        templateRows='repeat(1, 1fr)'
                        templateColumns='repeat(5, 1fr)'>
                        <GridItem rowSpan={2} colSpan={1}>
                            <Card>
                                <CardBody>
                                    <Image
                                        borderRadius='full'
                                        width={'220px'}
                                        src={memberInfo && memberInfo.profile_picture !== null ? memberInfo && memberInfo.profile_picture : PlaceholderImage}
                                        alt='Dan Abramov'
                                    />
                                    {
                                        edit ? 
                                            <>
                                                <Input type="file" onChange={bannerUpload} padding={"4px"} />
                                                <Box mt={'20px'}>
                                                    <FormControl>
                                                        <FormLabel fontWeight={'bold'}> First Name </FormLabel>
                                                        <Input type='text' id={'first_name'} placeholder='Please provide the first name' defaultValue={memberInfo && memberInfo.first_name} mb={'15px'} />
                                                    </FormControl>
                                                </Box>                                 
                                                <Box mt={'20px'}>
                                                    <FormControl>
                                                        <FormLabel fontWeight={'bold'}> Last Name </FormLabel>
                                                        <Input type='text' id={'last_name'} placeholder='Please provide the last name' defaultValue={memberInfo && memberInfo.last_name} mb={'15px'} />
                                                    </FormControl>
                                                </Box>                                 
                                            </>
                                        :
                                        <Box mt={'20px'}>
                                            <FormLabel fontWeight={'bold'}> User Name </FormLabel>
                                            <FormLabel>{memberInfo && memberInfo.first_name} {memberInfo && memberInfo.last_name}</FormLabel>
                                        </Box>
                                    }
                                    <Box mt={'15px'}>
                                        <Stack>
                                            <FormLabel fontWeight={'bold'} mb={'0'}> WebEx URL</FormLabel>
                                            {
                                                edit ? 
                                                    <Input type='url' id={'webex_url'} placeholder='Please provide webex URL' defaultValue={memberInfo && memberInfo.webex_url} /> 
                                                : 
                                                <Link href={`${memberInfo?.webex_url}`} color={'blue'} textDecoration={'underline'}> 
                                                    {memberInfo?.webex_url} 
                                                </Link>
                                            }
                                        </Stack>
                                    </Box>
                                </CardBody>
                            </Card>
                        </GridItem>
                        <GridItem colSpan={4} p={'20px'} pt={'0'}>
                            <Card>
                                <CardBody>
                                <SimpleGrid columns={[1, 2]} spacing='20px' mt={'10px'}>
                                        <Box>
                                            <Stack>
                                                <FormLabel fontWeight={'bold'}> Job Title </FormLabel>
                                                {
                                                    edit ?
                                                        <Input type='text' id={'job_title'} placeholder='Please provide the job title' defaultValue={memberInfo && memberInfo.job_title} mb={'15px'} />
                                                    : <Text textTransform={'capitalize'}> {memberInfo && memberInfo.job_title} </Text>
                                                }
                                            </Stack>
                                        </Box>
                                        <Box>
                                            <Stack>
                                                <FormLabel fontWeight={'bold'}> Email </FormLabel>
                                                {
                                                    edit ?
                                                        <Input type='email' id={'email'} placeholder='Please provide the email' defaultValue={memberInfo && memberInfo.email} mb={'15px'} readOnly disabled />
                                                    : <Link href={`mailto:${memberInfo && memberInfo.email}`} color={'blue'} textDecoration={'underline'}> 
                                                        {memberInfo && memberInfo.email} 
                                                    </Link>
                                                }
                                            </Stack>
                                        </Box>
                                        
                                        {/* <Box>
                                            <Stack>
                                                <FormLabel fontWeight={'bold'}> Role </FormLabel>
                                                {
                                                    edit ? 
                                                        <Select variant='filled' id={'role'} name={''}>
                                                            <option value={''}> Please select role from below list </option>
                                                            {
                                                                role.map(item=>
                                                                    <option value={item.value} selected={memberInfo && memberInfo.role === item.value ? true : false}> {item.label} </option>
                                                                )
                                                            }
                                                        </Select>
                                                    : //<Text textTransform={'capitalize'}> {memberInfo && memberInfo.role} </Text>
                                                    <>
                                                        {
                                                            role.map(item =>
                                                                memberInfo && memberInfo.role === item.value ? 
                                                                <Text textTransform={'capitalize'}> {item.label}</Text> : null
                                                            )
                                                        }
                                                    </>
                                                }
                                            </Stack>
                                        </Box> */}
                                        <Box>
                                            <Stack>
                                                <FormLabel fontWeight={'bold'}> Position </FormLabel>
                                                {
                                                    edit ? 
                                                        <Select variant='filled' id={'position'} name={''} placeholder=''>
                                                            <option value={''}> Please select position from below list </option>
                                                            {
                                                                position.map(item=>
                                                                    <option value={item.value} selected={memberInfo && memberInfo.position === item.value ? true : false}> {item.label} </option>
                                                                )
                                                            }
                                                        </Select>
                                                    : 
                                                    <>
                                                        {
                                                            position.map(item =>
                                                                memberInfo && memberInfo.position === item.value ? 
                                                                <Text textTransform={'capitalize'}> {item.label}</Text> : null
                                                            )
                                                        }
                                                    </>
                                                    
                                                    
                                                }
                                            </Stack>
                                        </Box>
                                        <Box>
                                            <Stack>
                                                <FormLabel fontWeight={'bold'}> Priority </FormLabel>
                                                {
                                                    edit ? 
                                                        <Input type='number' id={'priority'} placeholder='Please provide number to set priority' defaultValue={memberInfo && memberInfo.priority} /> 
                                                    : <Text textTransform={'capitalize'}>{memberInfo && memberInfo.priority}</Text>
                                                }
                                            </Stack>
                                        </Box>
                                        <Box>
                                          <FormControl display='flex' alignItems='center'>
                                                <FormLabel htmlFor='action_office_user' mb='0' fontWeight={'bold'}>
                                                    Is he/she an action office user?
                                                </FormLabel>
                                                {
                                                    edit ? 
                                                        <>
                                                            {/* {
                                                                memberInfo && memberInfo.action_office_user ? 
                                                                <Switch id='action_office_user' mt={'10px'} defaultChecked={true} onChange={onHandleChange}  /> : 
                                                                <Switch id='action_office_user' mt={'10px'} defaultChecked={false} onChange={onHandleChange} />
                                                            } */}
                                                            {memberInfo && memberInfo?.action_office_user ? <Switch id='action_office_user' mt={'10px'} defaultChecked={true} onChange={onHandleChange} /> : ''}
                                                            {memberInfo && memberInfo?.action_office_user === false ? <Switch id='action_office_user' mt={'10px'} onChange={onHandleChange} /> : ''}
                                                        </>
                                                    :
                                                        <>
                                                            {memberInfo && memberInfo?.action_office_user ? <Switch id='action_office_user' mt={'10px'} defaultChecked={true} disabled /> : ''}
                                                            {memberInfo && memberInfo?.action_office_user === false ? <Switch id='action_office_user' mt={'10px'} disabled /> : ''}
                                                            
                                                            {/* {
                                                                memberInfo && memberInfo.action_office_user ? 
                                                                <Switch id='action_office_user' mt={'10px'} defaultChecked={true} disabled /> : 
                                                                <Switch id='action_office_user' mt={'10px'} defaultChecked={false} disabled />
                                                            } */}
                                                        </>
                                                }
                                            </FormControl>
                                        </Box>
                                    </SimpleGrid>
                                    <Box>
                                        <FormLabel fontWeight={'bold'}> Description </FormLabel>
                                        {
                                            edit ?
                                                <JoditEditor
                                                    id={"description"}
                                                    ref={editor}
                                                    value={memberInfo && memberInfo.description}
                                                    tabIndex={1} // tabIndex of textarea
                                                    onChange={newContent => {}}
                                                /> 
                                            : <Text textTransform={'capitalize'}> {memberInfo && memberInfo.description != null  ? parse(memberInfo && memberInfo.description) : ''} </Text>
                                        }
                                    </Box>
                                    
                                    <Box>
                                        {
                                            edit ? 
                                            <Stack mt={'20px'}>
                                                <Button colorScheme='blue' onClick={updateProfile}> Update </Button>
                                            </Stack> : null
                                        }
                                    </Box>  
                                </CardBody>
                            </Card>
                        </GridItem>
                    </Grid>
                </CardBody>
            </Card>
        </>
    )
}

export default MemberDetails